import { dashboardApi } from 'store/api';
import { PESubscription } from 'types/airtable/pe.airtable.type';
import { SCPISubscription } from 'types/airtable/scpi.airtable.type';
import { ArtSubscription } from 'types/art.type';
import { AvLuxContract } from 'types/avLux.type';
import { CPM } from 'types/cpm-list.type';
import { CrowdfundingSubscription } from 'types/crowdfunding.type';
import { GirardinSubscription } from 'types/girardin.type';
import { EnvelopeType, InvestProjectType } from 'types/invest-subscription.type';
import { FinancialLostType, LostReactionType, PortfolioType } from 'types/investmentPreferences.type';
import { SavingsAccount } from 'types/savingsAccount.type';
import { StructuredProduct } from 'types/structured.type';

export enum AssetCPM {
	ACTIONS = 'Actions',
	IMMOBILIER = 'Immobilier',
	OBLIGATIONS = 'Obligations',
	FONDS_EURO = 'Fonds euro',
	PRIVATE_EQUITY = 'Private Equity',
	ART = 'Art',
}

type SCPISubscriptionCPM = {
	initialDepositAmount: NonNullable<SCPISubscription['initialDepositAmount']>;
	projectType: SCPISubscription['projectType'];
	nbShare: SCPISubscription['nbShare'];
	legalEntity: NonNullable<SCPISubscription['legalEntity']>;
	demembrementDuration?: SCPISubscription['demembrementDuration'];
	fundId: string;
};

type PESubscriptionCPM = {
	initialDepositAmount: NonNullable<PESubscription['initialDepositAmount']>;
	legalEntity: NonNullable<PESubscription['legalEntity']>;
	fundId: string;
};

type CrowdfundingSubscriptionCPM = {
	initialDepositAmount: number; // CrowdfundingSubscription['initialDepositAmount'] is a string
	nbShare: CrowdfundingSubscription['nbShare'];
	fundName: CrowdfundingSubscription['fundName'];
	provider: CrowdfundingSubscription['provider'];
	process: CrowdfundingSubscription['process'];
	airtableFundId: CrowdfundingSubscription['airtableFundId'];
};

type ArtSubscriptionCPM = {
	initialDepositAmount: number; // ArtSubscription['initialDepositAmount'] is a string
	fundName: ArtSubscription['fundName'];
	provider: ArtSubscription['provider'];
	airtableFundId: ArtSubscription['airtableFundId'];
};

type GirardinSubscriptionCPM = {
	initialDepositAmount: number; // GirardinSubscription['initialDepositAmount'] is a string
	provider: GirardinSubscription['provider'];
};

type AvLuxSubscriptionCPM = {
	initialDepositAmount: number; // AvLuxContract['initialDepositAmount'] is a string
	risk: NonNullable<AvLuxContract['risk']>;
	provider: AvLuxContract['provider'];
};

type CashSubscriptionCPM = {
	initialDepositAmount: number; // SavingsAccount['initialDepositAmount'] is a string
	provider: SavingsAccount['provider'];
	type: SavingsAccount['type'];
	legalEntity: SavingsAccount['legalEntity'];
	investmentDuration?: SavingsAccount['investmentDuration'];
};

type InvestSubscriptionCPM = {
	initialDepositAmount: number; // InvestSubscription['initialDepositAmount'] is a string
	recurrentDepositAmount?: number; // InvestSubscription['recurrentDepositAmount'] is a string
	envelopeType: EnvelopeType;
	projectType: InvestProjectType;
	investmentPreferences: {
		ESG: boolean;
		temperature?: number;
		timeHorizon?: number;
		birthYear?: number;
		workedYears?: number;
		hasEverUsedSavingPlan: boolean;
		financialLost: FinancialLostType;
		lostReaction: LostReactionType;
		portfolioType: PortfolioType;
		risk: number;
		customPortfolio?: Array<{ ISIN: string; repartition: number }>;
	};
};

type StructuredSubscriptionCPM = {
	airtableFundId: StructuredProduct['airtableFundId'];
	fundName: StructuredProduct['fundName'];
	isin: StructuredProduct['isin'];
	isGuaranteed: StructuredProduct['isGuaranteed'];
	initialDepositAmount: number; // InvestSubscription['initialDepositAmount'] is a string
};

type CPMVersement = {
	initialDepositAmount: number;
	versementRequestType: string;
	contractId: string;
};

type CPMCreateProductPropaleDTO = {
	ownerEmail: string;
	cpm: CPM;
	message?: string;
	notionURL?: string;
	estimatedDistributionRate?: number;
	isBlack: boolean;
	assets: AssetCPM[];
	SCPISubscriptions?: SCPISubscriptionCPM[];
	PESubscriptions?: PESubscriptionCPM[];
	investSubscriptions?: InvestSubscriptionCPM[];
	investVersements?: CPMVersement[];
	structuredSubscriptions?: StructuredSubscriptionCPM[];
	cashSubscriptions?: CashSubscriptionCPM[];
	artSubscriptions?: ArtSubscriptionCPM[];
	crowdfundingSubscriptions?: CrowdfundingSubscriptionCPM[];
	girardinSubscriptions?: GirardinSubscriptionCPM[];
	avLuxSubscriptions?: AvLuxSubscriptionCPM[];
};

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		createProposal: builder.mutation<Record<string, string[]>, CPMCreateProductPropaleDTO>({
			query: (body) => ({
				url: '/bo/cpm/propale-product',
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const { useCreateProposalMutation } = endpoints;
