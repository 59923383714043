import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons';
import { Button, HStack, Skeleton, Switch, useDisclosure, VStack } from '@chakra-ui/react';

import FilterPopovers from 'components/filters';
import SearchByFilter, { SearchBy, searchByFunc } from 'components/filters/SearchBy';
import BlockingInstancesTable from 'components/tables/BlockingInstanceTable';
import { isBIOverdue, isBIOverdueToCall } from 'features/ChangeOverdueDate';
import BlockingInstanceCreate, { Partner } from 'pages/ops/super/blocking-instance/BlockingInstanceCreate';
import { BlockingInstanceWithKyc, useGetBlockingInstancesQuery } from 'services/ops/blocking-instance';
import { BlockingInstanceLifecycle, BlockingInstanceStatus } from 'types/blocking-instance.type';
import { BOContext } from 'types/global.type';
import { isNotNone } from 'utils/functions';

import useBlockingInstanceFilters from './utils';

export type BlockingInstanceListProps = {
	context: Extract<BOContext, 'client' | 'blocking-instance'>;
	customSearchBy?: SearchBy; // force filter at route level
	customInput?: string; // force filter at route level
};

const BlockingInstanceList: FC<BlockingInstanceListProps> = ({ context, customSearchBy, customInput }) => {
	const {
		partnerFilter,
		setPartnerFilter,
		statusFilter,
		setStatusFilter,
		lifecycleFilter,
		setLifecycleFilter,
		onlyOverdue,
		setOnlyOverdue,
		searchBy,
		setSearchBy,
		input,
		setInput,
		toBeCalled,
		setToBeCalled,
		resetFilters,
	} = useBlockingInstanceFilters(context, customSearchBy, customInput);

	const location = useLocation();
	const { isOpen: isOpenCreate, onOpen: onOpenCreate, onClose: onCloseCreate } = useDisclosure();

	const { data: bis, isFetching: isBisFetching } = useGetBlockingInstancesQuery(
		{
			searchBy: customSearchBy ?? 'email',
			input: customInput ?? '',
		},
		{ refetchOnFocus: true, refetchOnReconnect: true, pollingInterval: 300000 },
	);

	const [selectedBI, setSelectedBI] = useState<BlockingInstanceWithKyc | null>(null);

	// when creating a blocking instance from a souscription page
	useEffect(() => {
		if (isNotNone(location.state?.deal)) onOpenCreate();
	}, [location.state?.deal, onOpenCreate]);

	const handleBlockingInstanceMatch = useCallback(
		(instance: BlockingInstanceWithKyc) => {
			setSelectedBI(instance);
			onOpenCreate();
		},
		[onOpenCreate],
	);

	const handleCreateBlockingInstance = useCallback(() => {
		setSelectedBI(null);
		onOpenCreate();
	}, [onOpenCreate]);

	const handleOnCloseCreate = useCallback(() => {
		setSelectedBI(null);
		onCloseCreate();
	}, [onCloseCreate]);

	return (
		<VStack w="100%" align="start">
			{context === 'blocking-instance' && (
				<HStack w="100%" justify="space-between">
					<HStack w="100%">
						<SearchByFilter
							search={input}
							onChangeSearch={setInput}
							onChangeSearchBy={setSearchBy}
							searchBy={searchBy}
						/>
						<FilterPopovers
							components={[
								{
									title: 'Statut',
									componentProps: {
										value: statusFilter,
										onChange: (v: string[]) => setStatusFilter(v as BlockingInstanceStatus[]),
										options: Object.values(BlockingInstanceStatus),
									},
								},
								{
									title: 'Lifecycle',
									componentProps: {
										value: lifecycleFilter,
										onChange: (v: string[]) => setLifecycleFilter(v as BlockingInstanceLifecycle[]),
										options: Object.values(BlockingInstanceLifecycle),
									},
								},
								{
									title: 'Partenaire',
									componentProps: {
										value: partnerFilter,
										onChange: setPartnerFilter,
										options: Object.values(Partner),
									},
								},
							]}
						/>
						<Button
							_hover={{ cursor: 'auto' }}
							rightIcon={<Switch onChange={(event) => setOnlyOverdue(event.target.checked)} isChecked={onlyOverdue} />}
						>
							Overdue
						</Button>
						<Button
							_hover={{ cursor: 'auto' }}
							rightIcon={<Switch onChange={(event) => setToBeCalled(event.target.checked)} isChecked={toBeCalled} />}
						>
							Call to do
						</Button>
						<Button colorScheme="blue" onClick={handleCreateBlockingInstance} leftIcon={<AddIcon />}>
							Créer
						</Button>
					</HStack>
					<Button
						variant="outline"
						onClick={() => {
							resetFilters();
						}}
					>
						Reset filters
					</Button>
				</HStack>
			)}

			<Skeleton isLoaded={!isBisFetching} w="100%">
				<BlockingInstancesTable
					context={context}
					blockingInstances={(bis ?? [])
						.filter((s) => searchByFunc(s, searchBy, input))
						.filter((s) => statusFilter.includes(s.status))
						.filter((s) => partnerFilter.includes(s.partner))
						.filter((s) => lifecycleFilter.includes(s.lifecycle))
						.filter((s) => (onlyOverdue ? isBIOverdue(s) : true))
						.filter((s) =>
							toBeCalled ? isBIOverdueToCall(s) && s.status === BlockingInstanceStatus.SENT_TO_CLIENT : true,
						)}
					onClick={handleBlockingInstanceMatch}
					selectedBlockingInstances={selectedBI ?? undefined}
					pageSize={50}
				/>
			</Skeleton>

			{isOpenCreate && (
				<BlockingInstanceCreate
					existingDeal={location.state?.deal}
					existingBlockingInstance={selectedBI ?? undefined}
					onClose={handleOnCloseCreate}
				/>
			)}
		</VStack>
	);
};

export default BlockingInstanceList;
