import { dashboardApi } from 'store/api';
import { UniverseFund } from 'types/airtable/invest.airtable.type';
import { PEFund } from 'types/airtable/pe.airtable.type';
import { SCPIFund, SCPIFundDemembrement } from 'types/airtable/scpi.airtable.type';
import { StructuredProductFund } from 'types/airtable/structured.airtable.type';
import { ArtFund } from 'types/art.type';
import { CrowdfundingFund } from 'types/crowdfunding.type';

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getInvestFunds: builder.query<UniverseFund[], void>({
			query: () => ({
				url: '/bo/fund-data/invest',
				method: 'GET',
			}),
		}),
		getArtWorks: builder.query<ArtFund[], void>({
			query: () => ({
				url: '/bo/fund-data/art',
				method: 'GET',
			}),
		}),
		getPeFunds: builder.query<PEFund[], void>({
			query: () => ({
				url: '/bo/fund-data/pe',
				method: 'GET',
			}),
		}),
		getPeFundById: builder.query<PEFund, string>({
			query: (fundId) => ({
				url: `/bo/fund-data/pe/${fundId}`,
				method: 'GET',
			}),
		}),
		getStructuredFunds: builder.query<StructuredProductFund[], void>({
			query: (params) => ({
				url: '/bo/fund-data/structured',
				method: 'GET',
				params,
			}),
		}),
		getCrowdfundingFunds: builder.query<CrowdfundingFund[], void>({
			query: () => ({
				url: '/bo/fund-data/crowdfunding',
				method: 'GET',
			}),
		}),
		getCrowdfundingFundById: builder.query<CrowdfundingFund, string>({
			query: (fundId) => ({
				url: `/bo/fund-data/crowdfunding/${fundId}`,
				method: 'GET',
			}),
		}),
		getScpiFunds: builder.query<SCPIFund[], void>({
			query: () => ({
				url: '/bo/fund-data/scpi',
				method: 'GET',
			}),
		}),
		getScpiFundById: builder.query<SCPIFund, string>({
			query: (fundId) => ({
				url: `/bo/fund-data/scpi/${fundId}`,
				method: 'GET',
			}),
		}),
		getScpiDemembrement: builder.query<SCPIFundDemembrement, string>({
			query: (fundId) => ({
				url: `/bo/fund-data/scpi/${fundId}/demembrement`,
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useGetArtWorksQuery,
	useGetPeFundsQuery,
	useGetPeFundByIdQuery,
	useLazyGetPeFundByIdQuery,
	useGetScpiFundsQuery,
	useGetScpiFundByIdQuery,
	useLazyGetScpiFundByIdQuery,
	useLazyGetScpiDemembrementQuery,
	useGetInvestFundsQuery,
	useGetStructuredFundsQuery,
	useGetCrowdfundingFundsQuery,
	useGetCrowdfundingFundByIdQuery,
	useLazyGetCrowdfundingFundByIdQuery,
} = endpoints;
