import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, HStack, Input, Skeleton, Text, Textarea, VStack } from '@chakra-ui/react';

import ReviewDocumentsTable from 'components/tables/ReviewDocumentsTable';
import useThemedToast from 'hooks/useThemedToast';
import { templateRelanceDoc } from 'pages/ops/super/blocking-instance/templateMail';
import {
	BlockingInstanceWithKyc,
	MissingDocumentToReview,
	useGetBlockingInstanceDocumentsQuery,
	useReviewBlockingInstancesMutation,
} from 'services/ops/blocking-instance';
import { BlockingInstanceLifecycle } from 'types/blocking-instance.type';
import { isNone, isNotNone } from 'utils/functions';

const mailPartner = `Bonjour,

Vous trouverez les documents ci-joints.

Bien à vous.`;

type BlockingInstanceReviewProps = {
	blockingInstance: BlockingInstanceWithKyc;
};

const BlockingInstanceReview: FC<BlockingInstanceReviewProps> = ({ blockingInstance }) => {
	const navigate = useNavigate();
	const toast = useThemedToast();

	const [message, setMessage] = useState(mailPartner);
	const [invalidDocument, setInvalidDocument] = useState<MissingDocumentToReview[]>([]);
	const [partnerEmail, setPartnerEmail] = useState<string>('');

	const [reviewBi, { isLoading: isReviewLoading }] = useReviewBlockingInstancesMutation();

	const { data: documents, isFetching: isDocumentsFetching } = useGetBlockingInstanceDocumentsQuery(
		{ id: blockingInstance?.id ?? '' },
		{ skip: isNone(blockingInstance) },
	);

	useEffect(() => {
		if (invalidDocument.length < 1) setMessage(mailPartner);
		else
			setMessage(
				templateRelanceDoc(
					blockingInstance?.user?.kyc?.firstName ?? '',
					invalidDocument.map((d) => d.documentName),
				),
			);
	}, [blockingInstance?.user?.kyc?.firstName, invalidDocument]);

	const handleViewSubscription = async () => {
		if (isNone(blockingInstance.linkedEntityId))
			return toast({ status: 'error', title: 'Impossible de trouver la souscritpion' });
		navigate(`/ops/super/subscription/${blockingInstance.linkedEntityId}?productType=${blockingInstance.product}`);
	};

	const handleReview = async (isValid: boolean) => {
		reviewBi({
			id: blockingInstance.id,
			message,
			isValid,
			invalidDocumentsId: invalidDocument.length ? invalidDocument.map((d) => d.id) : undefined,
			partnerEmail: isValid && isNone(blockingInstance.mailId) ? partnerEmail : undefined,
			noMissingDocuments: false,
			attachments:
				!invalidDocument.length && documents?.every(({ filename, url }) => isNotNone(filename) && isNotNone(url))
					? documents.map(({ documentName, filename, url }) => ({ documentName, filename: filename!, url: url! }))
					: undefined,
		})
			.unwrap()
			.then(() => toast({ status: 'success', title: 'Instance bloquante validée avec succès' }))
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	return (
		<VStack w="100%" align="start" spacing="16px">
			<Skeleton isLoaded={!isDocumentsFetching} w="100%">
				<ReviewDocumentsTable
					docList={documents ?? []}
					selectedDocs={invalidDocument}
					setSelectedDocs={setInvalidDocument}
				/>
			</Skeleton>

			{isNone(blockingInstance.mailId) && invalidDocument.length < 1 && (
				<VStack align="start" w="100%">
					<Text variant="Text-M-Medium">Email du partenaire</Text>
					<Input value={partnerEmail} onChange={(e) => setPartnerEmail(e.target.value)} />
				</VStack>
			)}

			<VStack align="start" w="100%">
				<Text variant="Text-M-Medium">Message pour le {invalidDocument.length > 0 ? 'client' : 'partenaire'}</Text>
				<Textarea
					h={invalidDocument.length < 1 ? '130px' : '270px'}
					value={message}
					onChange={(e) => setMessage(e.target.value)}
				/>
			</VStack>

			<HStack w="100%" justify="end">
				{invalidDocument.length === 0 && blockingInstance.lifecycle === BlockingInstanceLifecycle.MISSING_INFO && (
					<Button onClick={handleViewSubscription}>Consulter la souscription</Button>
				)}

				{invalidDocument.length === 0 && blockingInstance.lifecycle !== BlockingInstanceLifecycle.MISSING_INFO && (
					<Button onClick={() => handleReview(true)} isLoading={isReviewLoading}>
						Valider les documents
					</Button>
				)}

				{invalidDocument.length !== 0 && (
					<Button colorScheme="red" onClick={() => handleReview(false)} isLoading={isReviewLoading}>
						Refuser les documents
					</Button>
				)}
			</HStack>
		</VStack>
	);
};

export default BlockingInstanceReview;
