import { FormControl, FormLabel, HStack, VStack } from '@chakra-ui/react';

import NumberInputWithStepper from 'components/NumberInput';
import { AvLuxData } from 'pages/cpm/product-propale/propaleData.type';

type AvLuxCardProps = {
	data: AvLuxData;
	editData: React.Dispatch<React.SetStateAction<AvLuxData[]>>;
};

export const AvLuxCard = ({ data, editData }: AvLuxCardProps) => (
	<VStack align="start" spacing="16px" w="100%">
		<HStack w="100%">
			<FormControl>
				<FormLabel fontSize="sm">Montant</FormLabel>
				<NumberInputWithStepper
					step={1000}
					min={500}
					value={data.initialDepositAmount + '€'}
					onChange={(initialDepositAmount) =>
						editData((old) => {
							const newData = [...old];
							newData.splice(newData.indexOf(data), 1);
							return [...newData, { ...data, initialDepositAmount: +initialDepositAmount }].sort((a, b) => a.id - b.id);
						})
					}
				/>
			</FormControl>
			<FormControl>
				<FormLabel fontSize="sm" w="150px">
					Risque
				</FormLabel>
				<NumberInputWithStepper
					step={0.1}
					min={1}
					max={10}
					value={data.risk}
					onChange={(risk) => {
						editData((old) => {
							const newFunds = [...old];
							newFunds.splice(newFunds.indexOf(data), 1);
							return [...newFunds, { ...data, risk: +risk }].sort((a, b) => a.id - b.id);
						});
					}}
				/>
			</FormControl>
		</HStack>
	</VStack>
);
