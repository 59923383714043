import { FC } from 'react';
import { Button, HStack, Skeleton, Switch, VStack } from '@chakra-ui/react';

import FilterPopovers from 'components/filters';
import SearchByFilter, { SearchBy, searchByFunc } from 'components/filters/SearchBy';
import TransfersTable from 'components/tables/TransfersTable';
import { isDealOverdue } from 'features/ChangeOverdueDate';
import { AllDeal } from 'services/deal';
import { useGetStatusesQuery } from 'services/ops/status';
import { useGetTransfersQuery } from 'services/ops/transfer';
import { BOContext } from 'types/global.type';
import { TransferStatus } from 'types/perTransfer.type';

import useTransferFilters, { statusOptions } from './utils';

type TransferListProps = {
	context: Extract<BOContext, 'client' | 'transfer'>;
	customSearchBy?: SearchBy; // force filter at route level
	customInput?: string; // force filter at route level
};

const TransferList: FC<TransferListProps> = ({ customInput, customSearchBy, context }) => {
	const {
		input,
		setInput,
		searchBy,
		setSearchBy,
		statusFilter,
		setStatusFilter,
		onlyOverdue,
		setOnlyOverdue,
		resetFilters,
	} = useTransferFilters(context, customSearchBy, customInput);

	const { data: productTypeStatuses } = useGetStatusesQuery();
	const { data: transfers, isFetching } = useGetTransfersQuery(
		{
			searchBy: customSearchBy ?? 'email',
			input: customInput ?? '',
		},
		{ refetchOnFocus: true, refetchOnReconnect: true, pollingInterval: 300000 },
	);

	return (
		<VStack w="100%" align="start">
			{context === 'transfer' && (
				<HStack w="100%" justify="space-between">
					<HStack w="100%">
						<SearchByFilter
							searchBy={searchBy}
							onChangeSearchBy={setSearchBy}
							search={input}
							onChangeSearch={setInput}
						/>

						<FilterPopovers
							components={[
								{
									title: 'Statut',
									componentProps: {
										value: statusFilter,
										onChange: (v: string[]) => setStatusFilter(v as TransferStatus[]),
										options: statusOptions,
									},
								},
							]}
						/>
						<Button
							_hover={{ cursor: 'auto' }}
							rightIcon={<Switch isChecked={onlyOverdue} onChange={(event) => setOnlyOverdue(event.target.checked)} />}
						>
							Overdue
						</Button>
					</HStack>
					<Button
						variant="outline"
						onClick={() => {
							resetFilters();
						}}
					>
						Reset filters
					</Button>
				</HStack>
			)}

			<Skeleton isLoaded={!isFetching} w="100%">
				<TransfersTable
					context={context}
					productTypeStatuses={productTypeStatuses}
					transfers={(transfers ?? [])
						.filter((s) => searchByFunc(s, searchBy, input))
						.filter((s) => statusFilter.includes(s.status))
						.filter((s) => (onlyOverdue ? isDealOverdue(s as AllDeal, productTypeStatuses) : true))}
					pageSize={50}
				/>
			</Skeleton>
		</VStack>
	);
};

export default TransferList;
