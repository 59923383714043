import { dashboardApi } from 'store/api';

const endpoints = dashboardApi.injectEndpoints({
	endpoints: (builder) => ({
		getIsinAum: builder.query<{ totalAum: number }, { isin: string; excludeNegative: boolean }>({
			query: ({ isin, ...params }) => ({
				url: `bo/ops/aum/isin/${isin}`,
				params,
			}),
		}),
		getCrowdfundingAum: builder.query<{ totalAum: number }, { crowdfunding: string }>({
			query: ({ crowdfunding }) => ({
				url: `bo/ops/aum/crowdfunding/${crowdfunding}`,
			}),
		}),
		getArtAum: builder.query<{ totalAum: number }, { art: string }>({
			query: ({ art }) => ({
				url: `bo/ops/aum/art/${art}`,
			}),
		}),
	}),
});

export const { useLazyGetIsinAumQuery, useLazyGetCrowdfundingAumQuery, useLazyGetArtAumQuery } = endpoints;
