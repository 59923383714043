import {
	Button,
	Divider,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
} from '@chakra-ui/react';

import useThemedToast from 'hooks/useThemedToast';
import { OpsInvestSub } from 'services/deal';
import { useUpdateInvestSubscriptionRattachementMutation } from 'services/ops/rattachement';
import { SubscriptionStatus } from 'types/global.type';

export type ConfirmationModalRattachementProps = {
	show: boolean;
	subscription: OpsInvestSub;
	newStatus: SubscriptionStatus.REQUESTED | SubscriptionStatus.SIGNED | SubscriptionStatus.PARTNER_VALIDATION;
	newApicilSubscriptionNumber: string;
	ignoreEventsContractOpening: boolean;
	handleClose: () => void;
};

const ConfirmationModalRattachement = ({
	show,
	subscription,
	newStatus,
	newApicilSubscriptionNumber,
	ignoreEventsContractOpening,
	handleClose,
}: ConfirmationModalRattachementProps) => {
	const [updateSubscription, { isLoading }] = useUpdateInvestSubscriptionRattachementMutation();
	const toast = useThemedToast();

	const handleUpdate = async () => {
		updateSubscription({
			id: subscription.id,
			status: newStatus,
			externalSubscriptionId: newApicilSubscriptionNumber,
			ignoreEventsContractOpening: ignoreEventsContractOpening,
		})
			.unwrap()
			.then(() => {
				toast({
					title: 'La souscription a été mise à jour avec succès',
					status: 'success',
					isClosable: true,
				});
			})
			.catch((error) => {
				console.log(error);
				toast({
					title: 'Une erreur est survenue',
					status: 'error',
					description: error?.data?.message,
					isClosable: true,
				});
			});
		handleClose();
	};
	return (
		<Modal isOpen={show} onClose={handleClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Confirmation</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<VStack align="start" w="100%" spacing="16px">
						<VStack align="start" w="100%" spacing="16px">
							<Text>Contrat sélectionné :</Text>
							<Text>
								Numéro de projet Apicil : <b>{subscription.externalProviderId}</b>
							</Text>
							<Text>
								Statut : <b>{subscription.status}</b>
							</Text>
							<Text> Montant : {subscription.initialDepositAmount} </Text>
							<Text>
								Portefeuille : {subscription.investmentPreferences?.portfolioType} - Risque{' '}
								{subscription.investmentPreferences?.risk}{' '}
							</Text>
						</VStack>
						<Divider />

						<VStack align="start" w="100%" spacing="16px">
							{/* <Divider /> */}
							<Text>Le contrat sélectionné sera mis à jour avec les informations suivantes :</Text>
							<Text>
								Numéro de projet Apicil : <b>{newApicilSubscriptionNumber}</b>
							</Text>
							<Text>
								Statut : <b>{newStatus}</b>
							</Text>
							<Text>
								Emails marketing d'ouverture : <b>{ignoreEventsContractOpening ? 'Non' : 'Oui'}</b>
							</Text>
						</VStack>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="blue" mr={3} onClick={handleUpdate} isLoading={isLoading}>
						Confirmer
					</Button>
					<Button variant="ghost" onClick={handleClose} disabled={isLoading}>
						Annuler
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ConfirmationModalRattachement;
