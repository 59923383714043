import { useCallback, useMemo, useState } from 'react';

import useThemedToast from 'hooks/useThemedToast';
import {
	ArtData,
	AvLuxData,
	CashData,
	CrowdfundingFundData,
	GirardinData,
	InvestData,
	PeFundData,
	ScpiFundData,
	StructuredData,
	VersementData,
} from 'pages/cpm/product-propale/propaleData.type';
import { AssetCPM, useCreateProposalMutation } from 'services/cpm/product-propale';
import { OpsInvestContract } from 'services/deal';
import {
	useGetArtWorksQuery,
	useGetCrowdfundingFundsQuery,
	useGetInvestFundsQuery,
	useGetPeFundsQuery,
	useGetScpiFundsQuery,
	useGetStructuredFundsQuery,
	useLazyGetScpiDemembrementQuery,
} from 'services/fund-data';
import { CPM } from 'types/cpm-list.type';
import { LegalEntity } from 'types/global.type';
import { VersementRequestType } from 'types/invest-contract.type';
import { EnvelopeType, InvestProjectType } from 'types/invest-subscription.type';
import { FinancialLostType, LostReactionType, PortfolioType } from 'types/investmentPreferences.type';
import { SavingsAccountInvestmentDuration, SavingsAccountType } from 'types/savingsAccount.type';

import { hydrateAsset } from './cards/InvestCard';
import { avLuxSelectOptions, cashSelectOptions, girardinSelectOptions, investSelectOptions } from './utils';

const useAddProduct = () => {
	const toast = useThemedToast();

	const { data: scpiFunds, isFetching: isFetchingScpiFunds } = useGetScpiFundsQuery();
	const { data: peFunds, isFetching: isFetchingPeFunds } = useGetPeFundsQuery();
	const { data: artsFunds, isFetching: isFetchingArtFundss } = useGetArtWorksQuery();
	const { data: crwdfgDeals, isFetching: isFetchingCrwdfgDeals } = useGetCrowdfundingFundsQuery();
	const { data: investFunds, isFetching: isFetchingInvestFunds } = useGetInvestFundsQuery();
	const { data: structuredFunds, isFetching: isFetchingStructuredFunds } = useGetStructuredFundsQuery();

	const [selectedInvest, setSelectedInvest] = useState<InvestData[]>([]);
	const [selectedScpi, setSelectedScpiFunds] = useState<ScpiFundData[]>([]);
	const [selectedPe, setSelectedPeFunds] = useState<PeFundData[]>([]);
	const [selectedCrowdfunding, setSelectedCrowdfunding] = useState<CrowdfundingFundData[]>([]);
	const [selectedCash, setSelectedCash] = useState<CashData[]>([]);
	const [selectedArt, setSelectedArt] = useState<ArtData[]>([]);
	const [selectedGirardin, setSelectedGirardin] = useState<GirardinData[]>([]);
	const [selectedAvLux, setSelectedAvLux] = useState<AvLuxData[]>([]);
	const [selectedStructured, setSelectedStructured] = useState<StructuredData[]>([]);
	const [selectedVersement, setSelectedVersement] = useState<VersementData[]>([]);

	const [createProposal, { isLoading }] = useCreateProposalMutation();
	const [getScpiDemembrement] = useLazyGetScpiDemembrementQuery();

	const [fundIndex, setFundIndex] = useState(0);

	const totalAmount = useMemo(() => {
		const scpiSum = (selectedScpi.reduce((acc, curr) => curr.initialDepositAmount + acc, 0) * 100) / 100;
		const peSum = (selectedPe.reduce((acc, curr) => curr.initialDepositAmount + acc, 0) * 100) / 100;
		const investSum = (selectedInvest.reduce((acc, curr) => curr.initialDepositAmount + acc, 0) * 100) / 100;
		const cashSum = (selectedCash.reduce((acc, curr) => curr.initialDepositAmount + acc, 0) * 100) / 100;
		const artSum = (selectedArt.reduce((acc, curr) => curr.initialDepositAmount + acc, 0) * 100) / 100;
		const crowdfundingSum =
			(selectedCrowdfunding.reduce((acc, curr) => curr.initialDepositAmount + acc, 0) * 100) / 100;
		const girardinSum = (selectedGirardin.reduce((acc, curr) => curr.initialDepositAmount + acc, 0) * 100) / 100;
		const avLuxSum = (selectedAvLux.reduce((acc, curr) => curr.initialDepositAmount + acc, 0) * 100) / 100;
		const structuredSum = (selectedStructured.reduce((acc, curr) => curr.initialDepositAmount + acc, 0) * 100) / 100;
		const versementSum = (selectedVersement.reduce((acc, curr) => curr.initialDepositAmount + acc, 0) * 100) / 100;

		return Math.ceil(
			scpiSum +
				peSum +
				investSum +
				cashSum +
				artSum +
				crowdfundingSum +
				avLuxSum +
				girardinSum +
				structuredSum +
				versementSum,
		);
	}, [
		selectedScpi,
		selectedPe,
		selectedInvest,
		selectedCash,
		selectedArt,
		selectedCrowdfunding,
		selectedGirardin,
		selectedAvLux,
		selectedStructured,
		selectedVersement,
	]);

	const addPESub = useCallback(
		(pickedFund: string) => {
			const selectedFund = peFunds!.find((f) => f.id === pickedFund);
			if (!selectedFund) return;

			setSelectedPeFunds((prev) => [
				...prev,
				{
					id: fundIndex,
					fund: selectedFund,
					initialDepositAmount: selectedFund.ticket,
					legalEntity: LegalEntity.PHYSICAL,
					isReUp: false,
					reUpAmount: 0,
					reUpComment: '',
				},
			]);
		},
		[fundIndex, peFunds],
	);

	const addSCPISub = useCallback(
		async (pickedFund: string) => {
			const selectedFund = scpiFunds!.find((f) => f.id === pickedFund);
			if (!selectedFund) return;

			const minNbShare = Math.ceil(selectedFund.ticket / selectedFund.lastShareValuation);
			const demembrement = await getScpiDemembrement(selectedFund.id).unwrap();

			setSelectedScpiFunds((prev) => [
				...prev,
				{
					id: fundIndex,
					fund: selectedFund,
					projectType: 'PLEINE_PROPRIETE',
					nbShare: minNbShare,
					initialDepositAmount: selectedFund.lastShareValuation * minNbShare,
					legalEntity: LegalEntity.PHYSICAL,
					demembrementData: demembrement,
					demembrementDuration: 1,
				},
			]);
		},
		[fundIndex, getScpiDemembrement, scpiFunds],
	);

	const addCrowdfundingSub = useCallback(
		(pickedFund: string) => {
			const selectedDeal = crwdfgDeals!.find((f) => f.id === pickedFund)!;
			if (!selectedDeal) return;

			setSelectedCrowdfunding((prev) => [
				...prev,
				{ id: fundIndex, fund: selectedDeal, initialDepositAmount: selectedDeal.ticket },
			]);
		},
		[crwdfgDeals, fundIndex],
	);

	const addArtSub = useCallback(
		(pickedFund: string) => {
			const selectedArtwork = artsFunds!.find((f) => f.id === pickedFund)!;
			if (!selectedArtwork) return;

			setSelectedArt((prev) => [...prev, { id: fundIndex, fund: selectedArtwork, initialDepositAmount: 20000 }]);
		},
		[artsFunds, fundIndex],
	);

	const addInvestSub = useCallback(
		(pickedFund: string) => {
			const selectedProduct = investSelectOptions.find((f) => f.id === pickedFund);
			if (!selectedProduct) return;

			setSelectedInvest((prev) => [
				...prev,
				{
					id: fundIndex,
					initialDepositAmount: selectedProduct.id === PortfolioType.ELITE ? 10000 : 1000,
					risk: '5',
					green: 'no',
					duration: 5,
					envelope: EnvelopeType.AV,
					monthlySaving: selectedProduct.id === PortfolioType.CUSTOM ? 0 : 100,
					objective: InvestProjectType.OTHER,
					portfolioType: selectedProduct.id,
				},
			]);
		},
		[fundIndex],
	);

	const addCERSub = useCallback(
		(pickedFund: string) => {
			const selectedFund = cashSelectOptions!.find((f) => f.id === pickedFund);
			if (!selectedFund) return;

			setSelectedCash((prev) => [
				...prev,
				{
					id: fundIndex,
					initialDepositAmount: 100000,
					provider: selectedFund.id,
					type: SavingsAccountType.CER,
					legalEntity: LegalEntity.PHYSICAL,
				},
			]);
		},
		[fundIndex],
	);

	const addCATSub = useCallback(
		(pickedFund: string) => {
			const selectedFund = cashSelectOptions!.find((f) => f.id === pickedFund);
			if (!selectedFund) return;

			setSelectedCash((prev) => [
				...prev,
				{
					id: fundIndex,
					initialDepositAmount: 100000,
					provider: selectedFund.id,
					type: SavingsAccountType.CAT,
					legalEntity: LegalEntity.PHYSICAL,
					duration: SavingsAccountInvestmentDuration.TWELVE_MONTHS,
				},
			]);
		},
		[fundIndex],
	);

	const addGirardinSub = useCallback(
		(pickedFund: string) => {
			const selectedFund = girardinSelectOptions!.find((f) => f.id === pickedFund);
			if (!selectedFund) return;

			setSelectedGirardin((prev) => [
				...prev,
				{
					id: fundIndex,
					initialDepositAmount: 2500,
					provider: selectedFund.id,
				},
			]);
		},
		[fundIndex],
	);

	const addAvLuxSub = useCallback(
		(pickedFund: string) => {
			const selectedFund = avLuxSelectOptions!.find((f) => f.id === pickedFund);
			if (!selectedFund) return;

			setSelectedAvLux((prev) => [
				...prev,
				{
					id: fundIndex,
					initialDepositAmount: 100000,
					risk: 5,
					provider: selectedFund.id,
				},
			]);
		},
		[fundIndex],
	);

	const addStructuredSub = useCallback(
		(structuredFund: string) => {
			const selectedFund = structuredFunds!.find((f) => f.id === structuredFund);
			if (!selectedFund) return;

			setSelectedStructured((prev) => [
				...prev,
				{
					id: fundIndex,
					initialDepositAmount: 1_000,
					product: selectedFund,
				},
			]);
		},
		[fundIndex, structuredFunds],
	);

	const addVersement = useCallback(
		(pickedFund: string, dealInvest: OpsInvestContract) => {
			setSelectedVersement((prev) => [
				...prev,
				{
					id: fundIndex,
					initialDepositAmount: 100,
					contractCurrentAmount: dealInvest.amount,
					contractId: pickedFund,
					envelope: dealInvest.envelope.type,
					versementRequestType: VersementRequestType.PROGRAMME,
				},
			]);
		},
		[fundIndex],
	);

	const addFund = useCallback(
		(product: string, pickedFund: string, dealInvest?: OpsInvestContract) => {
			if (product === 'SCPI') addSCPISub(pickedFund);
			if (product === 'PE') addPESub(pickedFund);
			if (product === 'CROWDFUNDING') addCrowdfundingSub(pickedFund);
			if (product === 'ART') addArtSub(pickedFund);
			if (product === 'INVEST') addInvestSub(pickedFund);
			if (product === 'CAT') addCATSub(pickedFund);
			if (product === 'CER') addCERSub(pickedFund);
			if (product === 'GIRARDIN') addGirardinSub(pickedFund);
			if (product === 'AV_LUX') addAvLuxSub(pickedFund);
			if (product === 'STRUCTURED') addStructuredSub(pickedFund);
			if (product === 'VERSEMENT' && dealInvest) addVersement(pickedFund, dealInvest);

			setFundIndex((prev) => prev + 1);
		},
		// eslint-disable-next-line prettier/prettier
		[addSCPISub, addPESub, addCrowdfundingSub, addArtSub, addInvestSub, addCATSub, addCERSub, addGirardinSub, addAvLuxSub, addStructuredSub, addVersement],
	);

	const createPropale = async (data: {
		ownerEmail: string;
		cpm: CPM;
		notionURL?: string;
		message?: string;
		estimatedDistributionRate?: number;
		isBlack: boolean;
		assets: AssetCPM[];
	}) => {
		// if some asset of some invest propal is not valid, we prevent ths submition
		const someInvalidPropal = selectedInvest.some(
			(f) =>
				f.portfolioType === PortfolioType.CUSTOM &&
				f.customPortfolio!.some((asset) => !hydrateAsset(asset, investFunds).valid),
		);
		if (someInvalidPropal) {
			toast({ status: 'error', title: 'Erreur', description: 'Une propale est invalide' });
			return;
		}

		return createProposal({
			...data,
			SCPISubscriptions: selectedScpi.map((f) => ({
				projectType: f.projectType,
				nbShare: f.nbShare,
				initialDepositAmount: f.initialDepositAmount,
				legalEntity: f.legalEntity,
				demembrementDuration: f.demembrementDuration,
				fundId: f.fund.id,
			})),
			PESubscriptions: selectedPe.map((f) => ({
				initialDepositAmount: f.initialDepositAmount,
				legalEntity: f.legalEntity,
				fundId: f.fund.id,
				isReUp: f.isReUp,
				reUpAmount: f.reUpAmount,
				reUpComment: f.reUpComment,
			})),
			crowdfundingSubscriptions: selectedCrowdfunding.map((f) => ({
				initialDepositAmount: f.initialDepositAmount,
				nbShare: f.initialDepositAmount / f.fund.pricePerShare,
				airtableFundId: f.fund.id,
				fundName: f.fund.name,
				provider: f.fund.provider,
				process: f.fund.process,
			})),
			artSubscriptions: selectedArt.map((f) => ({
				initialDepositAmount: f.initialDepositAmount,
				airtableFundId: f.fund.id,
				fundName: f.fund.name,
				provider: f.fund.provider,
			})),
			investSubscriptions: selectedInvest.map((f) => ({
				envelopeType: f.envelope,
				projectType: f.objective,
				investmentPreferences: {
					portfolioType: f.portfolioType,
					ESG: f.green === 'no' ? false : true,
					temperature: f.green === '2.0' ? 2 : 2.5,
					timeHorizon: f.duration,
					birthYear: f.birthYear,
					workedYears: f.workedYears,
					hasEverUsedSavingPlan: true,
					financialLost: FinancialLostType.MAX_10,
					lostReaction: LostReactionType.UNKNOWN,
					risk: +f.risk,
					customPortfolio: f.portfolioType === PortfolioType.CUSTOM ? f.customPortfolio : undefined,
				},
				initialDepositAmount: f.initialDepositAmount,
				recurrentDepositAmount: f.monthlySaving,
			})),
			cashSubscriptions: selectedCash.map((f) => ({
				initialDepositAmount: f.initialDepositAmount,
				provider: f.provider,
				type: f.type,
				legalEntity: f.type === SavingsAccountType.CAT ? f.legalEntity : LegalEntity.PHYSICAL,
				investmentDuration: f.type === SavingsAccountType.CAT ? f.duration : undefined,
			})),
			girardinSubscriptions: selectedGirardin.map((g) => ({
				initialDepositAmount: g.initialDepositAmount,
				provider: g.provider,
			})),
			avLuxSubscriptions: selectedAvLux.map((f) => ({
				initialDepositAmount: f.initialDepositAmount,
				risk: f.risk,
				provider: f.provider,
			})),
			structuredSubscriptions: selectedStructured.map((f) => ({
				initialDepositAmount: f.initialDepositAmount,
				airtableFundId: f.product.id,
				fundName: f.product.name,
				isGuaranteed: f.product.isGuaranteed,
				isin: f.product.isin,
			})),
			investVersements: selectedVersement.map((f) => ({
				contractId: f.contractId,
				versementRequestType: f.versementRequestType,
				initialDepositAmount: f.initialDepositAmount,
			})),
		})
			.unwrap()
			.then((ids) => {
				toast({ status: 'success', title: 'Succès', description: 'Proposal créée avec succès' });
				setSelectedScpiFunds([]);
				setSelectedPeFunds([]);
				setSelectedCrowdfunding([]);
				setSelectedArt([]);
				setSelectedInvest([]);
				setSelectedCash([]);
				setSelectedGirardin([]);
				setSelectedAvLux([]);
				setSelectedStructured([]);
				setSelectedVersement([]);
				return ids;
			})
			.catch((err) => {
				toast({ status: 'error', title: 'Erreur', description: err.data.message });
				return null;
			});
	};

	return {
		addFund,
		totalAmount,
		createPropale,
		isCreateLoading: isLoading,
		selectedInvest,
		selectedScpi,
		selectedPe,
		selectedCrowdfunding,
		selectedCash,
		selectedArt,
		selectedGirardin,
		selectedAvLux,
		selectedStructured,
		selectedVersement,
		setSelectedInvest,
		setSelectedScpiFunds,
		setSelectedPeFunds,
		setSelectedCrowdfunding,
		setSelectedCash,
		setSelectedArt,
		setSelectedGirardin,
		setSelectedAvLux,
		setSelectedStructured,
		setSelectedVersement,
		scpiFunds,
		peFunds,
		artsFunds,
		crwdfgDeals,
		investFunds,
		structuredFunds,
		isFundsFetching:
			isFetchingScpiFunds ||
			isFetchingPeFunds ||
			isFetchingArtFundss ||
			isFetchingCrwdfgDeals ||
			isFetchingInvestFunds ||
			isFetchingStructuredFunds,
	};
};

export default useAddProduct;
