import { FC, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Card,
	Code,
	Divider,
	Heading,
	HStack,
	Input,
	Spinner,
	Step,
	StepIcon,
	StepIndicator,
	StepNumber,
	Stepper,
	StepSeparator,
	StepStatus,
	Text,
	useDisclosure,
	useSteps,
	VStack,
} from '@chakra-ui/react';

import CustomDrawer from 'components/Drawer';
import NumberInputWithStepper from 'components/NumberInput';
import PhoenixTable from 'components/tables/PhoenixUsersTable';
import useThemedToast from 'hooks/useThemedToast';
import {
	PhoenixUser,
	useCreateClassicUserMutation,
	useGetPhoenixUsersQuery,
	useGetPhoenixUserSubscriptionsQuery,
	useLazyGetPhoenixAutoLoginTokenQuery,
	useLazyGetPhoenixUserQuery,
} from 'services/phoenix';
import { dashboardApi } from 'store/api';
import { ramifyStacks } from 'utils/constants';

const steps = [
	{ title: 'Création du compte', showStep: true },
	{ title: 'Remplissage des informations', showStep: true },
	{ title: 'Remplissage du compte bancaire', showStep: false },
	{ title: 'Lien de connexion', showStep: true },
];

const getOnboardingStatus = (user?: PhoenixUser) => ({
	hasKyc: !!user?.kyc?.patrimonySources.length,
	hasKyb: !!user?.moralPersons?.at(0)?.moralPersonBeneficiaries?.length,
	hasBankInformation: !!user?.bankInformations?.length,
});

const CreatePhoenixClient: FC = () => {
	const toast = useThemedToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { activeStep, setActiveStep } = useSteps({ index: 0, count: steps.length });

	const [email, setEmail] = useState('');
	const [selectedUser, setSelectedUser] = useState<PhoenixUser | undefined>();
	const [nbShares, setNbShares] = useState('1');
	const [autologinLink, setAutologinLink] = useState('');

	const { data: users } = useGetPhoenixUsersQuery();
	const { data: userSubscriptions, isFetching: isSubscriptionFetching } = useGetPhoenixUserSubscriptionsQuery(
		{ userId: selectedUser?.id ?? '' },
		{ skip: !selectedUser },
	);
	const [createUser, { isLoading: isCreateLoading }] = useCreateClassicUserMutation();
	const [getUser, { isLoading: isUserLoading }] = useLazyGetPhoenixUserQuery();
	const [getAutologinToken] = useLazyGetPhoenixAutoLoginTokenQuery();

	const handleCreateUser = () => {
		createUser({ email, nbShares: +nbShares })
			.unwrap()
			.then((res) => {
				toast({ status: 'success', title: 'Utilisateur créé' });
				setSelectedUser(res);
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	const getAutologinLink = (path: string, openLink = true) => {
		getAutologinToken({ userId: selectedUser!.id })
			.unwrap()
			.then((res) => {
				const link = `${ramifyStacks.ramifyAppUrl.toString()}autologin?redirect=${path}&autologinToken=${res.token}`;
				if (openLink) window.open(link, '_blank');
				else setAutologinLink(link);
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	const refreshUser = async (step: 'kyc-kyb' | 'bank-info') => {
		getUser({ email: selectedUser!.email })
			.unwrap()
			.then((res) => {
				setSelectedUser(res);
				const { hasKyc, hasKyb, hasBankInformation } = getOnboardingStatus(res);
				if (step === 'kyc-kyb') {
					if (hasKyc && hasKyb) {
						toast({ status: 'success', title: 'Informations completes' });
						dashboardApi.util.invalidateTags(['PHOENIX_USERS']);
					} else toast({ status: 'error', title: 'Informations incompletes' });
				} else if (step === 'bank-info') {
					if (hasBankInformation) {
						toast({ status: 'success', title: 'Informations completes' });
						dashboardApi.util.invalidateTags(['PHOENIX_USERS']);
					} else toast({ status: 'error', title: 'Informations incompletes' });
				}
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	useEffect(() => {
		const { hasKyc, hasKyb, hasBankInformation } = getOnboardingStatus(selectedUser);
		if (hasKyc && hasKyb && hasBankInformation) setActiveStep(3);
		else if (hasKyc && hasKyb) setActiveStep(2);
		else if (!!selectedUser) setActiveStep(1);
		else setActiveStep(0);
	}, [selectedUser, setActiveStep]);

	return (
		<VStack
			align="start"
			spacing="32px"
			p="32px"
			borderRadius="10px"
			borderWidth="1px"
			borderColor="grey.200"
			w="100%"
			bg="white"
		>
			<Heading size="lg">Onboarding client - Phoenix</Heading>

			<PhoenixTable
				pageSize={20}
				users={users ?? []}
				selectedUser={selectedUser}
				onClick={(user) => {
					setSelectedUser(user);
					onOpen();
				}}
				onCreate={() => {
					setSelectedUser(undefined);
					onOpen();
				}}
			/>

			<CustomDrawer
				header={<Heading size="md">{selectedUser?.email ?? 'Onboarding'}</Heading>}
				isOpen={isOpen}
				onClose={() => {
					setSelectedUser(undefined);
					setEmail('');
					setAutologinLink('');
					onClose();
				}}
			>
				<VStack align="start" spacing="32px" w="100%">
					<Stepper size="lg" index={activeStep} w="100%">
						{steps
							.filter((s) => s.showStep)
							.map((step) => (
								<Step key={step.title}>
									<StepIndicator boxSize={8}>
										<StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
									</StepIndicator>

									<Box flexShrink="0">
										<Heading size="sm">{step.title}</Heading>
									</Box>

									<StepSeparator />
								</Step>
							))}
					</Stepper>

					{activeStep === 0 && (
						<VStack w="100%" align="start">
							<VStack w="100%" align="start" spacing="16px">
								<VStack w="100%" align="start">
									<Text fontWeight="bold">Email</Text>
									<Input
										value={email}
										onChange={(e) => setEmail(e.target.value.trim())}
										placeholder="Email"
										w="500px"
									/>
								</VStack>
								<VStack w="100%" align="start">
									<Text fontWeight="bold">Nombre de parts (une part = 187€)</Text>
									<NumberInputWithStepper
										value={nbShares}
										onChange={(e) => setNbShares(e)}
										placeholder="Nombre de parts"
										w="500px"
									/>
								</VStack>
								<Button colorScheme="blue" onClick={handleCreateUser} isLoading={isCreateLoading} isDisabled={!email}>
									Créer l'utilisateur et une souscription de {+nbShares * 187} €
								</Button>
							</VStack>
						</VStack>
					)}

					{(activeStep === 1 || activeStep === 2) && (
						<VStack w="100%" align="start" spacing="16px">
							{isSubscriptionFetching ? (
								<Spinner />
							) : !userSubscriptions || userSubscriptions?.length === 0 ? (
								<Text>Aucune souscription trouvée</Text>
							) : (
								<>
									<Text>Etape 1</Text>
									<Text>
										Connectez vous au compte de votre client et remplissez ses informations personnelles et
										professionnelles jusqu'à l'étape "Réglementaire".
									</Text>
									<Text>Une fois terminé, cliquez sur le bouton "Terminé" pour passer à l'étape suivante</Text>
									{activeStep === 1 && (
										<HStack w="100%" spacing="16px">
											<Button
												onClick={() =>
													getAutologinLink(`/immobilier/onboarding/${userSubscriptions[0].id}/connaissance-client`)
												}
											>
												Commencer
											</Button>

											<Button colorScheme="blue" onClick={() => refreshUser('kyc-kyb')} isLoading={isUserLoading}>
												Terminé
											</Button>
										</HStack>
									)}

									{activeStep === 2 && (
										<>
											<Divider />
											<Text>Etape 2</Text>
											<Text>
												Connectez vous au compte de votre client et ajoutez le compte bancaire Phoenix du client
											</Text>
											<Text>Une fois terminé, cliquez sur le bouton "Terminé" pour passer à l'étape suivante</Text>
											<HStack w="100%" spacing="16px">
												<Button onClick={() => getAutologinLink('/compte/comptes-bancaires')}>Commencer</Button>

												<Button colorScheme="blue" onClick={() => refreshUser('bank-info')} isLoading={isUserLoading}>
													Terminé
												</Button>
											</HStack>
										</>
									)}
								</>
							)}
						</VStack>
					)}

					{activeStep === 3 && (
						<VStack w="100%" align="start" spacing="16px">
							<Button onClick={() => getAutologinLink(`/phoenix`, false)}>Generer le lien de connexion</Button>
							{autologinLink && (
								<Card p="16px" w="100%">
									<Text>⚠️ Ce lien n'est valable que 14 jours</Text>
									<Code my="16px">{autologinLink}</Code>
									<HStack w="100%" spacing="16px">
										<Button flex={1} onClick={() => navigator.clipboard.writeText(autologinLink)}>
											Copier le lien
										</Button>
										<Button flex={1} onClick={() => window.open(autologinLink, '_blank')}>
											Ouvrir le lien
										</Button>
									</HStack>
								</Card>
							)}
						</VStack>
					)}
				</VStack>
			</CustomDrawer>
		</VStack>
	);
};

export default CreatePhoenixClient;
