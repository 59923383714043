import { FC, ReactNode } from 'react';
import { Link, Location, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { Box, Button, ButtonProps, HStack, Text, VStack } from '@chakra-ui/react';

import { BlockingInstanceIcon, ClientIcon, DealIcon, SubscriptionIcon, TransferIcon } from 'assets/icons';
import { isDealOverdue } from 'features/ChangeOverdueDate';
import { AllDeal } from 'services/deal';
import { useGetBlockingInstancesQuery } from 'services/ops/blocking-instance';
import { useGetStatusesQuery } from 'services/ops/status';
import { useGetTransfersQuery } from 'services/ops/transfer';
import { useGetSubscriptionsQuery } from 'services/subscription';
import { BlockingInstanceStatus } from 'types/blocking-instance.type';
import { PermissionDisplayGuard } from 'utils/guards';
import { BoPermission } from 'utils/permissions';

import BlockingInstanceMenu from './blocking-instance';
import ClientRouter from './client';
import DealRouter from './deal';
import SubscriptionRouter from './subscription';
import TransferMenu from './transfer';

type DrawerButtonProps = {
	children: ReactNode;
	path: string;
	permission: BoPermission;
	icon: ReactNode;
	amount?: number;
	location: Location;
};

export const DrawerButton: FC<DrawerButtonProps> = ({ path, icon, permission, children, amount, location }) => (
	<PermissionDisplayGuard permission={permission}>
		<HStack
			as={(props: ButtonProps) => <Button as={Link} to={path} {...props} />}
			w="100%"
			p="8px"
			justify="start"
			spacing="12px"
			bg={location.pathname.includes(path) ? 'gray.100' : 'white'}
		>
			{icon}
			<HStack spacing="8px" w="100%" justify="space-between">
				<Text fontSize="16px">{children}</Text>
				{amount && (
					<Box borderRadius="10px" py="2px" px="12px" bg={!location.pathname.includes(path) ? 'gray.100' : 'white'}>
						<Text fontSize="12px">{amount}</Text>
					</Box>
				)}
			</HStack>
		</HStack>
	</PermissionDisplayGuard>
);

const SuperToolLayout = () => {
	const location = useLocation();

	const { data: bi } = useGetBlockingInstancesQuery({ searchBy: 'email', input: '' });
	const { data: sub } = useGetSubscriptionsQuery({ searchBy: 'email', input: '' });
	const { data: transfers } = useGetTransfersQuery({ searchBy: 'email', input: '' });
	const { data: productTypeStatuses } = useGetStatusesQuery();

	return (
		<HStack h="calc(100vh - 65px)" w="100%" spacing="0px">
			<VStack
				h="100%"
				w="250px"
				py="24px"
				px="8px"
				alignSelf="start"
				spacing="4px"
				bg="white"
				borderRightWidth="1px"
				borderColor="grey.200"
				position="fixed"
				top="50"
				left="0"
				overflowY="auto"
			>
				<DrawerButton
					path="client"
					permission={BoPermission.CLIENT_READ}
					location={location}
					icon={<ClientIcon boxSize="24px" />}
				>
					Client
				</DrawerButton>
				<DrawerButton
					path="subscription"
					permission={BoPermission.DEAL_READ}
					location={location}
					icon={<SubscriptionIcon boxSize="24px" />}
					amount={sub?.length}
				>
					Souscription
				</DrawerButton>
				<DrawerButton
					path="deal"
					permission={BoPermission.DEAL_READ}
					location={location}
					icon={<DealIcon boxSize="24px" />}
				>
					Deal
				</DrawerButton>
				<DrawerButton
					path="blocking-instance"
					permission={BoPermission.BLOCKING_INSTANCE_READ}
					location={location}
					icon={<BlockingInstanceIcon boxSize="24px" />}
					amount={
						bi?.filter(
							(b) =>
								b.status === BlockingInstanceStatus.OPEN || b.status === BlockingInstanceStatus.DOCUMENTS_SUBMITTED,
						).length
					}
				>
					{bi ? 'Instances Bloq...' : 'Instances Bloquantes'}
				</DrawerButton>
				<DrawerButton
					path="transfer"
					permission={BoPermission.TRANSFER_READ}
					location={location}
					icon={<TransferIcon boxSize="24px" />}
					amount={transfers?.filter((t) => isDealOverdue(t as AllDeal, productTypeStatuses)).length}
				>
					Transfert
				</DrawerButton>
			</VStack>

			<Box h="100%" w="calc(100% - 250px)" ml="250px" px="32px" py="24px" bg="grey.50" overflowY="auto">
				<Outlet />
			</Box>
		</HStack>
	);
};

const SuperToolRouter = (): JSX.Element => (
	<Routes>
		<Route element={<SuperToolLayout />}>
			<Route path="client/*" element={<ClientRouter basePath="ops" />} />
			<Route path="subscription/*" element={<SubscriptionRouter />} />
			<Route path="deal/*" element={<DealRouter />} />
			<Route path="blocking-instance/*" element={<BlockingInstanceMenu />} />
			<Route path="transfer/*" element={<TransferMenu />} />
			<Route path="*" element={<Navigate to="/ops/super/client" replace />} />
		</Route>
	</Routes>
);

export default SuperToolRouter;
