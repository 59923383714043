import { useEffect, useMemo, useState } from 'react';

import { useGetClientKYCQuery, useGetClientMoralPersonsQuery } from 'services/client';
import { AllDeal, OpsInvestContract, useLazyGetDealsQuery } from 'services/deal';
import { useGetUserBankAccountsQuery } from 'services/ops/bank-account';
import { AnyObject, ProductType } from 'types/global.type';
import { EnvelopeType } from 'types/invest-subscription.type';
import { fieldTranslation } from 'utils/fieldTranslation';
import { isEmptyObject, isNone, removeObjectFields, renameObjectFields } from 'utils/functions';

import generateUserInfoPayload from './DisplayUserInfos';

type ClientInformationProps = {
	email?: string;
	subscription?: AllDeal;
};

const useGetUserData = ({ email, subscription }: ClientInformationProps) => {
	const { data: kycAdequationFiscality } = useGetClientKYCQuery({ email: email ?? '' }, { skip: isNone(email) });
	const { data: moralPersons } = useGetClientMoralPersonsQuery({ email: email ?? '' }, { skip: isNone(email) });
	const { data: bankInformation } = useGetUserBankAccountsQuery({ email: email ?? '' }, { skip: isNone(email) });

	const [getUserDeals] = useLazyGetDealsQuery();

	const [contractPerBankAccount, setContractPerBankAccount] = useState<OpsInvestContract>();

	const moralPersonOfSubscription = useMemo(() => {
		if (
			isNone(moralPersons) ||
			isNone(subscription) ||
			!(
				subscription?.productType === ProductType.PE ||
				subscription?.productType === ProductType.SCPI ||
				subscription?.productType === ProductType.ART ||
				subscription?.productType === ProductType.GIRARDIN ||
				subscription?.productType === ProductType.CASH
			)
		)
			return undefined;
		return moralPersons.find((moralPerson) => moralPerson.id === subscription?.moralPersonId);
	}, [moralPersons, subscription]);

	const BankAccountOfSubscription = useMemo(() => {
		if (isNone(subscription)) return undefined;
		if (subscription?.productType === ProductType.PE || subscription?.productType === ProductType.SCPI) {
			return bankInformation?.find((bankAccount) => bankAccount.id === subscription?.bankInformationId);
		} else if (subscription?.productType === ProductType.INVEST && subscription?.dealType === 'TRANSFER') {
			return bankInformation?.find((bankAccount) => bankAccount.id === contractPerBankAccount?.bankInformationId);
		} else if (subscription?.productType === ProductType.GIRARDIN) {
			return undefined;
		}
		return bankInformation?.find((bankAccount) => bankAccount.id === subscription?.bankInformationId);
	}, [bankInformation, subscription, contractPerBankAccount]);

	const subscriptionDataTodisplay: Record<string, AnyObject> = useMemo(() => {
		const fieldToRemove = [
			'bulletinSigned',
			'fund',
			'fundCoverPicture',
			'user',
			'userId',
			'enableSync',
			'fundOriginDocument',
			'statusUpdateTimeTracking',
			'productType',
			'hasBlockingInstance',
			'legalEntity',
			'lastShareValuation',
			'valuation',

			'endDate',
			'paymentAt',
			'partnerValidationAt',
			'openingDate',
			'kycValidationAt',
			'createdAt',
			'pendingAt',
			'requestedAt',
			'registeredAt',
			'completedAt',
			'terminatedAt',
			'signedAt',
			'contractSentToClientAt',
		];
		const dataToDisplay = removeObjectFields(subscription as AnyObject, fieldToRemove);
		return isEmptyObject(dataToDisplay) ? dataToDisplay : renameObjectFields(dataToDisplay, fieldTranslation)!;
	}, [subscription]);

	useEffect(() => {
		if (subscription?.productType === ProductType.INVEST && subscription?.dealType === 'TRANSFER') {
			getUserDeals({ searchBy: 'email', input: email })
				.then((data) => {
					const getContractPer = data?.data?.find(
						(deal) =>
							deal?.productType === ProductType.INVEST &&
							deal.dealType === 'CONTRACT' &&
							deal.envelope.type === EnvelopeType.PER &&
							deal.status === 'COMPLETED',
					);
					setContractPerBankAccount(getContractPer as OpsInvestContract);
				})
				.catch((error) => {
					console.error('Error fetching user deals:', error);
					setContractPerBankAccount(undefined);
				});
		}
	}, [subscription, email, getUserDeals]);

	const categoriesData = useMemo(
		() =>
			generateUserInfoPayload({
				kycAdequationHistoriqueFiscale: kycAdequationFiscality,
				moralPersonOfSubscription,
				BankAccountOfSubscription,
				subscription,
				subscriptionDataTodisplay,
			}),
		// eslint-disable-next-line prettier/prettier
		[kycAdequationFiscality, moralPersonOfSubscription, BankAccountOfSubscription, subscription, subscriptionDataTodisplay],
	);

	return categoriesData;
};

export default useGetUserData;
