import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
	Button,
	chakra,
	Checkbox,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Input,
	Select,
	Text,
	Textarea,
	VStack,
	Wrap,
} from '@chakra-ui/react';

import DocumentButton from 'components/documentButton';
import useAssignedOps, { SelectAssignedOps } from 'hooks/useAssignedOps';
import useFund from 'hooks/useFund';
import useThemedToast from 'hooks/useThemedToast';
import { AllDeal, OpsPeDeal, OpsScpiDeal } from 'services/deal';
import { RamifyDoc } from 'services/document';
import {
	EmailSubscriptionCreation,
	SendEmailSubscription,
	useSendSubscriptionEmailMutation,
} from 'services/subscription';
import { LegalEntity } from 'types/global.type';
import { OPS, opsDisplayName, opsToEmail } from 'types/ops-list.type';
import { documentDataMap } from 'utils/documentNamingMap';
import { isNone } from 'utils/functions';

const defaultMessage = `Bonjour,

Vous trouverez ci-joint le dossier de souscription du client cité en objet de ce mail.
Merci de nous confirmer la bonne réception du dossier et la validation de ce dernier. N'hésitez pas si des éléments seraient manquants à nous les demander.

Cordialement,
L'équipe de Gestion de Ramify`;

type SubscriptionSendApiProps = {
	subscription: AllDeal;
	defaultText?: string;
	defaultReceiver?: string;
	defaultSubject?: string;
	isTransfert?: boolean;
	selectedDocuments: RamifyDoc[];
	setSelectedDocuments: (docs: RamifyDoc[]) => void;
};

const SubscriptionSendMail: FC<SubscriptionSendApiProps> = ({
	subscription,
	defaultText = defaultMessage,
	defaultReceiver,
	defaultSubject,
	isTransfert,
	selectedDocuments,
	setSelectedDocuments,
}) => {
	const toast = useThemedToast();
	const navigate = useNavigate();

	const methods = useForm<SendEmailSubscription>({
		defaultValues: {
			sender: 'amelie.yuan@ramify.fr',
			receiver: defaultReceiver ?? '',
			cc: 'middle-office@ramify.fr',
			ownerEmail: subscription.user.email,
			subscriptionId: subscription.id,
			message: defaultText,
			subject: defaultSubject,
			sendClientConfirmationMail: subscription.hasBlockingInstance,
			productType: isTransfert ? 'TRANSFER' : subscription!.productType,
		},
	});
	const {
		setValue,
		handleSubmit,
		register,
		formState: { errors },
	} = methods;

	const fund = useFund(subscription);
	const [sendMail, { isLoading: isEmailSending }] = useSendSubscriptionEmailMutation();

	useEffect(() => {
		if (!subscription) return;

		const subjectValue = `Ramify // Souscription - ${subscription.user.kyc.lastName} ${subscription.user.kyc.firstName}`;
		if (subscription.user.kyc && !defaultSubject) setValue('subject', subjectValue);
		if (fund && !defaultSubject) setValue('subject', `${subjectValue} - ${fund.name}`);
		if (fund && !defaultReceiver) setValue('receiver', fund['Email - MO/BO'] ?? '');
	}, [subscription, defaultReceiver, defaultSubject, fund, setValue, isTransfert]);

	const { assignedOps, onChangeAssignedOps } = useAssignedOps();

	const handleSendEmail = handleSubmit((data: SendEmailSubscription) => {
		const formatedPayload: EmailSubscriptionCreation = {
			...data,
			receiver: data.receiver.split(/[ ,]+/),
			cc: !!data.cc ? data.cc.split(/[ ,]+/) : undefined,
			bcc: !!data.bcc ? data.bcc.split(/[ ,]+/) : undefined,
		};

		sendMail({
			...formatedPayload,
			assignedOps,
			attachments: selectedDocuments.map(({ documentName, filename, url }) => ({ documentName, filename, url })),
		})
			.unwrap()
			.then(() => {
				navigate('..');
				toast({ status: 'success', title: 'Succès', description: 'Souscription envoyée avec succès' });
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	});

	if (isNone(subscription)) return null;

	return (
		<chakra.form w="100%" onSubmit={handleSendEmail}>
			<VStack align="start" w="100%">
				<FormProvider {...methods}>
					{subscription.hasBlockingInstance && (
						<HStack>
							<Checkbox {...methods.register('sendClientConfirmationMail')} />
							<Text>Envoyer un mail de confirmation au client</Text>
						</HStack>
					)}
				</FormProvider>
				<SelectAssignedOps assignedOps={assignedOps} onChangeAssignedOps={onChangeAssignedOps} />

				{(subscription as OpsPeDeal | OpsScpiDeal).legalEntity === LegalEntity.MORAL && (
					<Heading size="md">⚠️ DOSSIER PERSONNE MORALE ⚠️</Heading>
				)}
				{subscription.user.isPhoenix &&
					'fundPartnerName' in subscription &&
					subscription.fundPartnerName === 'Novaxia' && (
						<Heading size="md">⚠️ DOSSIER PHOENIX NOVAXIA - Voir avec les devs en cas de problème/instance ⚠️</Heading>
					)}

				<VStack w="100%">
					<HStack w="100%" align="start" borderWidth="2px" borderColor="gray.100" p="8px">
						<VStack flex={1} align="start">
							<FormControl w="100%" isInvalid={!!errors.sender}>
								<HStack w="100%" align="start">
									<FormLabel w="120px">Expediteur</FormLabel>
									<Select {...register('sender', { required: true })}>
										{Object.values(OPS)
											.filter((v) => ![OPS.AMELIE].includes(v))
											.map((ops) => (
												<option key={ops} value={opsToEmail[ops]}>
													{opsDisplayName[ops]} ({opsToEmail[ops]})
												</option>
											))}
									</Select>
								</HStack>
							</FormControl>
							<FormControl w="100%" isInvalid={!!errors.receiver}>
								<HStack w="100%" align="start">
									<FormLabel w="120px">Destinataire</FormLabel>
									<Input placeholder="Destinataire" {...register('receiver', { required: true })} />
								</HStack>
								{errors.receiver && <FormErrorMessage>Ce champ est requis</FormErrorMessage>}
							</FormControl>
							<FormControl w="100%" isInvalid={!!errors.cc}>
								<HStack w="100%" align="start">
									<FormLabel w="120px">Cc</FormLabel>
									<Input placeholder="Cc" {...register('cc', { required: true })} />
								</HStack>
								{errors.cc && <FormErrorMessage>Ce champ est requis</FormErrorMessage>}
							</FormControl>
							<FormControl w="100%" isInvalid={!!errors.bcc}>
								<HStack w="100%" align="start">
									<FormLabel w="120px">Bcc</FormLabel>
									<Input placeholder="optionnel" {...register('bcc', { required: false })} />
								</HStack>
							</FormControl>
							<FormControl w="100%" isInvalid={!!errors.subject}>
								<HStack w="100%" align="start">
									<FormLabel w="120px">Objet</FormLabel>
									<Input placeholder="Objet" {...register('subject', { required: true })} />
								</HStack>
								{errors.subject && <FormErrorMessage>Ce champ est requis</FormErrorMessage>}
							</FormControl>

							<Wrap shouldWrapChildren align="start" p="4px">
								{selectedDocuments.map((doc) => (
									<DocumentButton
										key={doc.url}
										name={documentDataMap[doc.documentName]?.displayName ?? doc.documentName}
										onDelete={() => setSelectedDocuments(selectedDocuments.filter((d) => d.url !== doc.url))}
									/>
								))}
							</Wrap>
						</VStack>

						<VStack flex={1} align="end" spacing="8px">
							<FormControl w="100%" isInvalid={!!errors.message}>
								<Textarea h="185px" placeholder="Message" {...register('message', { required: true })} />
								{errors.message && <FormErrorMessage>Ce champ est requis</FormErrorMessage>}
							</FormControl>
							<Button
								colorScheme="blue"
								isDisabled={selectedDocuments.length === 0}
								isLoading={isEmailSending}
								type="submit"
							>
								Envoyer
							</Button>
						</VStack>
					</HStack>
				</VStack>
			</VStack>
		</chakra.form>
	);
};

export default SubscriptionSendMail;
