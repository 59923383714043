import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, chakra, FormLabel, HStack, Input, Text, Textarea, useDisclosure, VStack } from '@chakra-ui/react';

import DatePicker from 'components/DatePicker';
import useDealStatus from 'hooks/useDealStatus';
import useThemedToast from 'hooks/useThemedToast';
import { AllDeal, useUpdateDealMutation, useUploadAirtableDocsMutation } from 'services/deal';
import { ProductType, SubscriptionStatus } from 'types/global.type';
import { isNone, isNotNone } from 'utils/functions';

import YesNoIcon from './icons/YesNo';
import AlertDialog from './AlertDialog';
import CustomModal from './Modal';

type AirtableDocs = Record<string, { label: string; content: FileList | null }>;

type RequiredDocsUploadProps = {
	files: AirtableDocs;
	addFiles: (key: string, value: FileList) => void;
};

const RequiredDocsUpload: FC<RequiredDocsUploadProps> = ({ files, addFiles }) => {
	const { onToggle: onToggleFile, isOpen: isOpenFile } = useDisclosure();

	const inputFile = useRef<HTMLInputElement | null>(null);

	const [viewFile, setViewFile] = useState<string | null>(null);
	const viewFileContent = useMemo(
		() => (isNotNone(viewFile) && isNotNone(files) ? files[viewFile].content : null),
		[files, viewFile],
	);

	return (
		<VStack align="start" mt="16px">
			{Object.entries(files).map(([key, file]) => (
				<VStack key={key} align="start">
					<HStack>
						<Text
							_hover={isNotNone(file.content) ? { cursor: 'pointer', textDecoration: 'underline' } : undefined}
							onClick={() => {
								onToggleFile();
								setViewFile(key);
							}}
						>
							{file.label}
						</Text>
						<Input type="file" ref={inputFile} display="none" onChange={(e) => addFiles(key, e.target.files!)} />
						{isNone(file.content) ? (
							<Button onClick={() => inputFile.current?.click()}>Ajouter</Button>
						) : (
							<YesNoIcon isTrue />
						)}
					</HStack>
				</VStack>
			))}

			{isNotNone(viewFileContent) && (
				<CustomModal isOpen={isOpenFile} onClose={onToggleFile}>
					{viewFileContent[0]?.type.includes('image') ? (
						<chakra.img src={window.URL.createObjectURL(viewFileContent[0])} m="auto" />
					) : (
						<chakra.iframe src={window.URL.createObjectURL(viewFileContent[0])} w="100%" h="100%" />
					)}
				</CustomModal>
			)}
		</VStack>
	);
};

const defaultFiles: AirtableDocs = {
	sharesAttestation: { content: null, label: 'Attestation des parts' },
};

const NextStatusModal = ({ isOpen, onToggle, deal }: { isOpen: boolean; onToggle: () => void; deal: AllDeal }) => {
	const toast = useThemedToast();

	const { nextStatus } = useDealStatus(deal);

	const [triggerUpdateStatus, { isLoading }] = useUpdateDealMutation();
	const [triggerUploadAirtableDocs, { isLoading: isLoadingUploadAirtableDocs }] = useUploadAirtableDocsMutation();

	const [files, setFiles] = useState<AirtableDocs>(defaultFiles);
	const [nbShares, setNbShares] = useState<number>();
	const [typePart, setTypePart] = useState<string>();
	const [comment, setComment] = useState<string>();
	const [effectiveDate, setEffectiveDate] = useState<Date>(new Date());

	const needsDoc = useMemo(
		() =>
			deal.status === SubscriptionStatus.REGISTERED &&
			(deal.productType === ProductType.SCPI ||
				deal.productType === ProductType.PE ||
				deal.productType === ProductType.CROWDFUNDING ||
				deal.productType === ProductType.ART ||
				deal.productType === ProductType.GIRARDIN),
		[deal.productType, deal.status],
	);

	const registeredStatusInfoFilled = useMemo(() => {
		// attestationDesParts is required for all products that reach the REGISTERED status
		if (!Object.values(files).every((f) => isNotNone(f.content))) return false;
		if (deal.productType === ProductType.GIRARDIN) return true;
		if (deal.productType === ProductType.CROWDFUNDING) return true;
		if (deal.productType === ProductType.ART) return true;
		if (deal.productType === ProductType.PE) return isNotNone(typePart);
		if (deal.productType === ProductType.SCPI) return isNotNone(nbShares) && isNotNone(effectiveDate);
	}, [deal.productType, effectiveDate, files, nbShares, typePart]);

	const handleClose = useCallback(() => {
		onToggle();
		setFiles(defaultFiles);
		setNbShares(undefined);
		setTypePart(undefined);
	}, [onToggle]);

	const handleUpdateStatus = useCallback(async () => {
		if (isNone(nextStatus)) return;

		try {
			// upload shares docs and data first if needed, dopending on the product type
			if (needsDoc) {
				const payload = new FormData();

				payload.append('dealId', deal.id);
				payload.append('productType', deal.productType);
				if (nbShares) payload.append('nbShare', nbShares.toString());
				if (typePart) payload.append('partType', typePart);
				if (deal.productType === ProductType.SCPI && effectiveDate)
					payload.append('effectiveDate', effectiveDate.toISOString());
				Object.entries(files).forEach(([key, value]) => {
					if (isNotNone(value.content)) payload.append(key, value.content[0]);
				});

				await triggerUploadAirtableDocs(payload).unwrap();
			}

			await triggerUpdateStatus({
				id: deal.id,
				productType: deal.productType,
				status: nextStatus,
				properties: { id: deal.opsPropertiesId, comment },
			}).unwrap();

			toast({ title: 'Statut mis à jour', status: 'success' });
			handleClose();
		} catch (error) {
			toast({ title: 'Erreur lors de la mise a jour du statut', status: 'error' });
		}
		// eslint-disable-next-line prettier/prettier
	}, [comment, deal.id, deal.opsPropertiesId, deal.productType, effectiveDate, files, handleClose, nbShares, needsDoc, nextStatus, toast, triggerUpdateStatus, triggerUploadAirtableDocs, typePart]);


	useEffect(() => {
		if (isNone(deal) || (deal.productType !== ProductType.SCPI && deal.productType !== ProductType.PE)) return;
		setNbShares(deal.nbShare);
	}, [deal]);

	return (
		<AlertDialog
			isOpen={isOpen}
			onClose={handleClose}
			header="Changement de statut du deal"
			body={
				<VStack align="start" w="100%">
					<VStack align="start" w="100%">
						<Text>
							Produit: <b>{deal.productType}</b>
						</Text>
						<Text>
							Client: <b>{deal.user.email}</b>
						</Text>
						<Text>
							Statut actuel: <b>{deal.status}</b>
						</Text>
						<Text>
							Nouveau statut: <b>{nextStatus}</b>
						</Text>

						{needsDoc && (
							<VStack align="start" mt="16px" w="100%">
								{(deal.productType === ProductType.PE || deal.productType === ProductType.SCPI) && (
									<VStack w="100%" spacing="0px" align="start">
										<FormLabel>Nombre de parts</FormLabel>
										<Input type="number" value={nbShares} onChange={(event) => setNbShares(+event.target.value)} />
									</VStack>
								)}
								{deal.productType === ProductType.PE && (
									<VStack w="100%" spacing="0px" align="start">
										<FormLabel>Type de part</FormLabel>
										<Input value={typePart} onChange={(event) => setTypePart(event.target.value)} />
									</VStack>
								)}
								{deal.productType === ProductType.SCPI && (
									<VStack w="100%" spacing="0px" align="start">
										<FormLabel>Date d'effet</FormLabel>
										<DatePicker selected={effectiveDate} onChange={(d) => isNotNone(d) && setEffectiveDate(d)} />
									</VStack>
								)}
								<RequiredDocsUpload
									files={files}
									addFiles={(key, value) => {
										setFiles((prevFiles) => ({ ...prevFiles, [key]: { content: value, label: prevFiles[key].label } }));
									}}
								/>
							</VStack>
						)}
						<Textarea
							mt="16px"
							w="100%"
							value={comment}
							onChange={(event) => setComment(event.target.value)}
							placeholder="Laisser un commentaire"
						/>
					</VStack>
				</VStack>
			}
			footer={
				<HStack>
					<Button isLoading={isLoading || isLoadingUploadAirtableDocs} onClick={handleClose}>
						Annuler
					</Button>
					<Button
						isDisabled={isNone(nextStatus) || (needsDoc && !registeredStatusInfoFilled)}
						isLoading={isLoading || isLoadingUploadAirtableDocs}
						onClick={handleUpdateStatus}
						colorScheme="blue"
					>
						Valider
					</Button>
				</HStack>
			}
		/>
	);
};

export default NextStatusModal;
