import { ReactNode } from 'react';
import { Button, Heading, Text, VStack } from '@chakra-ui/react';

type CustomCardProps = {
	title: string;
	description: string;
	buttonText: string;
	onClick: () => void;
	isLoading?: boolean;
	isDisabled?: boolean;
	body?: ReactNode;
};

const BasicCard = ({ title, description, buttonText, onClick, isLoading, isDisabled, body }: CustomCardProps) => (
	<VStack
		p="32px"
		borderRadius="10px"
		borderWidth="1px"
		borderColor="grey.200"
		bg="white"
		w="400px"
		spacing="16px"
		align="left"
	>
		<Heading size="md">{title}</Heading>
		<Text>{description}</Text>
		{body}
		<Button mt="auto" onClick={onClick} isLoading={isLoading} isDisabled={isDisabled}>
			{buttonText}
		</Button>
	</VStack>
);

export default BasicCard;
