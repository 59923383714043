import { FC, useState } from 'react';
import { HStack, Input, Text, VStack } from '@chakra-ui/react';

import BasicCard from 'components/BasicCard';
import useThemedToast from 'hooks/useThemedToast';
import { useLazyGetCrowdfundingAumQuery } from 'services/ops/aum';
import { displayMoney } from 'utils/functions';

const CrowdfundingCard: FC = () => {
	const toast = useThemedToast();

	const [computeCrowdfundingAum, { data, isFetching: isCrowdFetching }] = useLazyGetCrowdfundingAumQuery();

	const [crowd, setCrowd] = useState('');

	return (
		<BasicCard
			title="Collecte Crowdfunding"
			description="Obtenir les informations sur un deal Crowdfunding"
			buttonText="Obtenir"
			isLoading={isCrowdFetching}
			onClick={() => {
				computeCrowdfundingAum({ crowdfunding: crowd })
					.unwrap()
					.catch((error) => {
						toast({ status: 'error', title: 'Erreur', description: error.data.message });
					});
			}}
			body={
				<VStack align="start">
					<Input placeholder="Crowdfunding" value={crowd} onChange={(e) => setCrowd(e.target.value)} />
					{data?.totalAum && (
						<HStack>
							<Text variant="Text-M-Bold">Collecte totale :</Text>
							<Text>{displayMoney(data.totalAum.toString())}</Text>
						</HStack>
					)}
				</VStack>
			}
		/>
	);
};

export default CrowdfundingCard;
