import { Checkbox, CheckboxProps } from '@chakra-ui/react';

const CheckboxFilter = (props: CheckboxProps) => (
	<Checkbox
		bg="white"
		px="8px"
		height="40px"
		borderRadius="4px"
		border="2px solid"
		borderColor="grey.200"
		_hover={{ borderColor: 'gray.700' }}
		_checked={{ borderColor: 'blue.500' }}
		{...props}
	/>
);

export default CheckboxFilter;
