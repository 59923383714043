import { LabArbitrageTableResponse } from 'services/qis';
import { BankInformation } from 'types/bank-information.type';
import { Envelope } from 'types/invest-subscription.type';

import { InvestmentPreferences } from './investmentPreferences.type';
import { KYC } from './kyc.type';
import { User } from './user.type';

export enum InvestContractLifecycle {
	RESILIATION_PERIOD = 'RESILIATION_PERIOD',
	OPEN = 'OPEN',
	TERMINATED = 'TERMINATED',
}

export type ContractHolding = {
	ISIN: string;
	montantEuro: string;
	nbShares: number;
};

export type InvestProgrammedVersement = {
	id: string;
	contractId: string;
	amount: string;
	startDate: string;
	period: string;
};

export type InvestContract = {
	id: string;
	createdAt: string;
	updatedAt: string;
	userId: string;
	synchronisedAt: string | null;
	effectDate: string | null;
	envelopeId: string;
	bankInformationId: string;
	lifecycle: InvestContractLifecycle;
	externalProviderId: string;
	amount: number;
	unvaluedBalance: number;
	totalVersement: number | null;
	hubspotId: string | null;
	subscriptionId: string | null;
	rebalancingData: LabArbitrageTableResponse | null;
	opsPropertiesId?: string;
};

export type PopulatedInvestContract = InvestContract & {
	programmedVersement: InvestProgrammedVersement | null;
	envelope: Envelope;
	investmentPreferences: InvestmentPreferences | null;
	holdings: ContractHolding[];
	bankInformation: BankInformation;
};

export type ContractRebalancing = InvestContract & {
	rebalancingData: LabArbitrageTableResponse;
	investmentPreferences: InvestmentPreferences;
	user: User & {
		kyc: KYC;
	};
};

export enum VersementRequestType {
	PROGRAMME = 'PROGRAMME',
	COMPLEMENTAIRE = 'COMPLEMENTAIRE',
}
