import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
	Button,
	Card,
	chakra,
	Checkbox,
	Divider,
	FormControl,
	FormLabel,
	Heading,
	HStack,
	Input,
	InputGroup,
	InputRightElement,
	Skeleton,
	Spinner,
	Text,
	VStack,
} from '@chakra-ui/react';

import useThemedToast from 'hooks/useThemedToast';
import {
	DefiscalisationPropalBody,
	useCreateDefiscalisationPropalMutation,
	useGetResultFiscaliteForClientQuery,
} from 'services/cpm/fiscality-propale';
import { User } from 'types/user.type';

import { InvestFormContext } from './investContent';

const inputWidth = { base: '100%', md: '456px' };

type DefiscalisationPropalBodyForm = Omit<DefiscalisationPropalBody, 'esg'> & {
	esg: 'no' | '2.0' | '2.5';
};

export type PropalDefiscalisationProps = {
	user: User;
	onPrev: () => void;
};

const PropalDefiscalisation = ({ user, onPrev }: PropalDefiscalisationProps) => {
	const toast = useThemedToast();

	const [templatePropal, setTemplatePropal] = useState<DefiscalisationPropalBody['typePropal']>([]);
	const [precisionAum, setPrecisionAum] = useState(false);

	const { data: resultOptim, isFetching: isOptimFetching } = useGetResultFiscaliteForClientQuery({ id: user.id });
	const [createNotion, { isLoading: isGenerating }] = useCreateDefiscalisationPropalMutation();

	const methods = useForm<DefiscalisationPropalBodyForm>({
		shouldUnregister: true,
		defaultValues: {
			useBlackFees: user.isBlack,
		},
	});
	const {
		handleSubmit,
		register,
		formState: { isValid },
	} = methods;

	const onFormSubmit = handleSubmit((data: DefiscalisationPropalBodyForm) => {
		createNotion({
			...data,
			email: user.email,
			portfolioType: data.portfolioType || 'ESSENTIAL',
			riskScore: data.riskScore || 5,
			esg: data.esg === 'no' ? false : true,
			temperature: data.esg === 'no' ? 2.5 : Number(data.esg) || 2.5,
			useBlackFees: data.useBlackFees,
			typePropal: templatePropal,
		})
			.unwrap()
			.then(() =>
				toast({
					title: 'Succès',
					description: 'La propale a été générée avec succès',
					status: 'success',
				}),
			)
			.catch(() =>
				toast({
					title: 'Erreur',
					description: 'Une erreur est survenue lors de la génération de la propale',
					status: 'error',
				}),
			);
	});

	return (
		<chakra.form onSubmit={onFormSubmit} w="100%">
			<VStack w="100%" align="start" spacing="24px">
				<Divider />
				<VStack w="100%" align="start" spacing="4px">
					<HStack w="100%" spacing="12px">
						<Text>Vous êtes sur le compte de:</Text>
						<Heading size="sm">{user.email}</Heading>
					</HStack>
					<HStack w="100%" spacing="12px">
						<Text>Le client est:</Text>
						<Heading size="sm">{user.isBlack ? 'Black' : 'Non black'}</Heading>
					</HStack>
					<Checkbox {...register('useBlackFees')}>Appliquer les frais black</Checkbox>
				</VStack>

				<Divider />

				<FormControl>
					<FormLabel>Sélectionnez les produits souhaités</FormLabel>
					<HStack w="100%" spacing="24px">
						<Checkbox
							value="per"
							isChecked={templatePropal.includes('per')}
							onChange={(e) =>
								setTemplatePropal((prev) => (e.target.checked ? [...prev, 'per'] : prev.filter((v) => v !== 'per')))
							}
						>
							PER
						</Checkbox>
						<Checkbox
							value="pe"
							isChecked={templatePropal.includes('pe')}
							onChange={(e) =>
								setTemplatePropal((prev) => (e.target.checked ? [...prev, 'pe'] : prev.filter((v) => v !== 'pe')))
							}
						>
							Private Equity
						</Checkbox>
						{/* <Checkbox
							value="girardin"
							isChecked={templatePropal.includes('girardin')}
							onChange={(e) =>
								setTemplatePropal((prev) =>
									e.target.checked ? [...prev, 'girardin'] : prev.filter((v) => v !== 'girardin'),
								)
							}
						>
							Girardin
						</Checkbox> */}
					</HStack>
				</FormControl>

				{templatePropal.length && (
					<VStack align="start" w="100%" spacing="24px">
						<VStack w="100%" align="start">
							<HStack w="100%" spacing="24px">
								<FormControl maxW={inputWidth}>
									<FormLabel>Montant global à investir :</FormLabel>
									<InputGroup>
										<Input type="number" {...register('amountToInvest', { valueAsNumber: true, required: true })} />
										<InputRightElement children="€" />
									</InputGroup>
								</FormControl>
								<Card bg="gray.50" p="16px 24px">
									<Skeleton w="100%" minH="40px" isLoaded={!isOptimFetching}>
										{resultOptim && (
											<VStack w="100%" align="start">
												<Heading size="sm">
													Montant recommandé par QIS: {resultOptim.optimal_amount_to_invest} €
												</Heading>
												<Text>PER : {resultOptim.per_amount} €</Text>
												<Text>Private Equity : {resultOptim.pe_amount} €</Text>
												{/* <Text>Girardin : {0} €</Text> */}
											</VStack>
										)}
									</Skeleton>
								</Card>
							</HStack>
							{templatePropal.length > 1 && (
								<Checkbox onChange={(e) => setPrecisionAum(e.target.checked)}>Fournir plus de précision</Checkbox>
							)}
						</VStack>

						{precisionAum === true && templatePropal.length > 1 && (
							<HStack w="100%" justify="space-between">
								{templatePropal.includes('per') && (
									<FormControl maxW={inputWidth}>
										<FormLabel>Montant à investir dans le PER</FormLabel>
										<InputGroup>
											<Input
												type="number"
												{...register('amountToInvestPER', { valueAsNumber: true, required: true })}
											/>
											<InputRightElement children="€" />
										</InputGroup>
									</FormControl>
								)}
								{templatePropal.includes('pe') && (
									<FormControl maxW={inputWidth}>
										<FormLabel>Montant à investir dans le Private Equity</FormLabel>
										<InputGroup>
											<Input type="number" {...register('amountToInvestPE', { valueAsNumber: true, required: true })} />
											<InputRightElement children="€" />
										</InputGroup>
									</FormControl>
								)}
								{templatePropal.includes('girardin') && (
									<FormControl maxW={inputWidth}>
										<FormLabel>Montant à investir dans le Girardin</FormLabel>
										<InputGroup>
											<Input
												type="number"
												{...register('amountToInvestGirardin', { valueAsNumber: true, required: true })}
											/>
											<InputRightElement children="€" />
										</InputGroup>
									</FormControl>
								)}
							</HStack>
						)}

						{templatePropal.includes('per') && (
							<>
								<Divider />
								<Heading size="md">PER</Heading>
								<FormProvider {...methods}>
									<InvestFormContext />
								</FormProvider>
							</>
						)}

						{templatePropal.includes('pe') && (
							<>
								<Divider />
								<Heading size="md">Private Equity</Heading>
								<Text>Pas de customisation pour l'instant</Text>
							</>
						)}

						{templatePropal.includes('girardin') && (
							<>
								<Divider />
								<Heading size="md">Girardin</Heading>
								<Text>Pas de customisation pour l'instant</Text>
							</>
						)}
					</VStack>
				)}

				<Divider />

				<HStack w="100%" justify="space-between">
					<Button onClick={onPrev}>Précédent</Button>
					{isValid && templatePropal.length && (
						<Button colorScheme="blue" type="submit" isLoading={isGenerating}>
							{isGenerating ? (
								<HStack>
									Génération en cours ... <Spinner />
								</HStack>
							) : (
								'Générer le notion'
							)}
						</Button>
					)}
				</HStack>
			</VStack>
		</chakra.form>
	);
};

export default PropalDefiscalisation;
