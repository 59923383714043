import { SubscriptionStatus } from './global.type';
import { InvestmentPreferences } from './investmentPreferences.type';

export enum EnvelopeType {
	PER = 'PER',
	AV = 'AV',
	AV_HYBRIDE = 'AV_HYBRIDE',
}

export enum VehiculeCodeInvest {
	P2 = 'P2', // AV Liberalys Vie
	IL = 'IL', // PER
	RAMV = 'RAMV', // AV Ramify Vie
	RAMP = 'RAMP', // PER Ramify
	SV09 = 'SV09', // LUX Hybrid
	GENERALI_AV = 'param.48504851', // AV 100% Fonds euro Generali
}

export interface Envelope {
	id: string;
	type: EnvelopeType;
	name: string;
	partnerCode: VehiculeCodeInvest;
}

export enum PeriodicityType {
	MONTHLY = 'MONTHLY',
	QUARTERLY = 'QUARTERLY',
	SEMI_ANNUALLY = 'SEMI_ANNUALLY',
	ANNUALLY = 'ANNUALLY',
}

export enum BeneficiaryType {
	SPOUSE = 'SPOUSE',
	CHILDREN = 'CHILDREN',
	HEIRS = 'HEIRS',
	WRITTEN = 'WRITTEN',
}

export enum FundOriginType {
	PROFESSIONAL_INCOME = 'PROFESSIONAL_INCOME',
	REAL_ESTATE = 'REAL_ESTATE',
	SUCCESSION_DONATION = 'SUCCESSION_DONATION',
	ASSET_SELLOUT = 'ASSET_SELLOUT',
	GOODS_SELLOUT = 'GOODS_SELLOUT',
}

export enum RepresentativeType {
	INDIVIDUAL = 'INDIVIDUAL',
	PROFESSIONAL = 'PROFESSIONAL',
}

export type BankInformations = {
	id: string;
	IBAN: string;
	BIC: string;
	holder: string;
};

export enum InsuranceProvider {
	APICIL = 'APICIL',
	GENERALI = 'GENERALI',
}

export enum InvestProjectType {
	RETIREMENT = 'RETIREMENT',
	OTHER = 'OTHER',
}

export type InvestSubscriptionStatus =
	| SubscriptionStatus.REQUESTED
	| SubscriptionStatus.SIGNED
	| SubscriptionStatus.PARTNER_VALIDATION
	| SubscriptionStatus.PAYMENT
	| SubscriptionStatus.COMPLETED
	| SubscriptionStatus.TERMINATED;

export interface InvestSubscription {
	id: string;
	userId: string;
	envelopeId: string;
	externalProviderId: string | null;
	timeHorizon: string | null;
	projectType: InvestProjectType;
	bankInformationId: string | null;
	opsPropertiesId?: string;
	extraData: Partial<{ workedYears: number; birthYear: number }> | null;
	status: InvestSubscriptionStatus;
	initialDepositAmount: string;
	recurrentDepositAmount: string;
	recurrentDepositperiod: PeriodicityType;
	beneficiary: BeneficiaryType | null;
	freeBeneficiaryClause: string | null;
	fundOriginType: FundOriginType | null;
	representativeType: RepresentativeType;
	provider: InsuranceProvider;
	hubspotId: string | null;

	ignoreEventsContractOpening?: boolean;
	createdAt: string;
	updatedAt: string;
	signedAt?: string;
	partnerValidationAt?: string;
	paymentAt?: string;
	registeredAt?: string;
	completedAt?: string;
	terminatedAt?: string;
}

export interface PopulatedInvestSubscription extends InvestSubscription {
	envelope: Envelope;
	investmentPreferences: InvestmentPreferences | null;
}
