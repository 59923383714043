import { InstanceDocumentData } from 'services/ops/blocking-instance';
import { documentDataMap, DocumentName } from 'utils/documentNamingMap';

export const templateMissingDoc = (firstname: string, missingDocuments: InstanceDocumentData[]) => `Bonjour ${firstname}

Nous avons bien reçu votre demande de souscription, merci de votre confiance.

Il nous manque toutefois quelques éléments afin de pouvoir la finaliser :

${missingDocuments
	.map((doc) => `- ${documentDataMap[doc.documentName].displayName ?? doc.documentName} : ${doc.comment}`)
	.join('\n')}

Vous pouvez les transmettre depuis votre espace investisseur, à l’onglet “Pièces justificatives complémentaires”, ou en cliquant <a href=https://app.ramify.fr/compte/document>ici</a>

Je reste à votre disposition pour toute information complémentaire.

Vous souhaitant une excellente journée.`;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const templateRoadblock = (firstname: string, missingDocuments: InstanceDocumentData[]) => `Bonjour ${firstname}

Nous avons eu un retour de notre partenaire concernant votre souscription.

Afin de pouvoir la finaliser, pouvez-vous nous transmettre :

${missingDocuments
	.map((doc) => `- ${documentDataMap[doc.documentName].displayName ?? doc.documentName} : ${doc.comment}`)
	.join('\n')}

Vous pouvez les transmettre depuis votre espace investisseur, à l’onglet “Pièces justificatives complémentaires”, ou en cliquant <a href=https://app.ramify.fr/compte/document>ici</a>

Je reste à votre disposition pour toute information complémentaire.

Vous souhaitant une excellente journée.`;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const templateBlockedOperation = (
	firstname: string,
	missingDocuments: InstanceDocumentData[],
) => `Bonjour ${firstname}

Nous avons eu un retour de notre partenaire concernant votre opération de versement.

Afin de pouvoir la finaliser, pouvez-vous nous transmettre :

${missingDocuments
	.map((doc) => `- ${documentDataMap[doc.documentName].displayName ?? doc.documentName} : ${doc.comment}`)
	.join('\n')}

Vous pouvez les transmettre depuis votre espace investisseur, à l’onglet “Pièces justificatives complémentaires”, ou en cliquant <a href=https://app.ramify.fr/compte/document>ici</a>

Je reste à votre disposition pour toute information complémentaire.

Vous souhaitant une excellente journée.`;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const templateUpdatingInfo = (
	firstname: string,
	missingDocuments: InstanceDocumentData[],
) => `Bonjour ${firstname}

Nous avons eu un retour de notre partenaire concernant la mise à jour de vos coordonnées.

Afin de pouvoir la finaliser, pouvez-vous s’il vous plaît nous transmettre :

${missingDocuments
	.map((doc) => `- ${documentDataMap[doc.documentName].displayName ?? doc.documentName} : ${doc.comment}`)
	.join('\n')}

Vous pouvez les transmettre depuis votre espace investisseur, à l’onglet “Pièces justificatives complémentaires”, ou en cliquant <a href=https://app.ramify.fr/compte/document>ici</a>

Je reste à votre disposition pour toute information complémentaire.

Vous souhaitant une excellente journée.`;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const templateRelanceDoc = (firstname: string, missingDocs: DocumentName[]) => `Bonjour ${firstname}

Merci pour le document.

Malheureusement, celui-ci n'est toujours pas conforme, pour la raison suivante :

${missingDocs.map((doc) => `- ${documentDataMap[doc].displayName ?? doc}`).join('\n')}

Pouvez-vous s'il vous plait me transmettre le document ajusté ?

Bien à vous.`;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const templateNoMissingDocs = (firstname: string) => `Bonjour ${firstname}

Afin de finaliser votre opération, notre partenaire nous demande les informations suivantes :



Vous pouvez nous faire un retour en répondant directement à ce mail.

Dans l'attente de votre retour, je vous souhaite une excellente journée.`;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const templateContextNote = () => `Bonjour,

Le client souhaite investir à sa guise sur les marchés, sans contrainte de temps.`;
