import { Box, FormLabel, HStack, Select, VStack } from '@chakra-ui/react';

import NumberInputWithStepper from 'components/NumberInput';
import { CashData } from 'pages/cpm/product-propale/propaleData.type';
import { LegalEntity } from 'types/global.type';
import { SavingsAccountInvestmentDuration, SavingsAccountType } from 'types/savingsAccount.type';

type CashCardProps = {
	data: CashData;
	editData: React.Dispatch<React.SetStateAction<CashData[]>>;
};

export const CashCard = ({ data, editData }: CashCardProps) => (
	<VStack align="start" spacing="16px" w="100%">
		<HStack w="100%">
			<Box flex="1">
				<FormLabel fontSize="sm">Montant</FormLabel>
				<NumberInputWithStepper
					step={1000}
					min={500}
					value={data.initialDepositAmount + '€'}
					onChange={(initialDepositAmount) =>
						editData((old) => {
							const newData = [...old];
							newData.splice(newData.indexOf(data), 1);
							return [...newData, { ...data, initialDepositAmount: +initialDepositAmount }].sort((a, b) => a.id - b.id);
						})
					}
				/>
			</Box>
		</HStack>

		{data.type === SavingsAccountType.CAT && (
			<HStack w="100%">
				<Box flex="1">
					<FormLabel fontSize="sm">Durée</FormLabel>
					<Select
						value={data.duration}
						onChange={(e) =>
							editData((old) => {
								const newData = [...old];
								newData.splice(newData.indexOf(data), 1);
								return [...newData, { ...data, duration: e.target.value as SavingsAccountInvestmentDuration }].sort(
									(a, b) => a.id - b.id,
								);
							})
						}
					>
						{/* <option value="THREE_MONTHS">3 mois</option> */}
						{/* <option value="SIX_MONTHS">6 mois</option> */}
						<option value="TWELVE_MONTHS">12 mois</option>
						<option value="TWENTY_FOUR_MONTHS">24 mois</option>
						<option value="THIRTY_SIX_MONTHS">36 mois</option>
						<option value="FOURTY_EIGHT_MONTHS">48 mois</option>
						<option value="SIXTY_MONTHS">60 mois</option>
					</Select>
				</Box>
				<Box flex="1">
					<FormLabel fontSize="sm">Entité Légale</FormLabel>
					<Select
						value={data.legalEntity}
						onChange={(e) =>
							editData((old) => {
								const newData = [...old];
								newData.splice(newData.indexOf(data), 1);
								return [...newData, { ...data, legalEntity: e.target.value as LegalEntity }].sort(
									(a, b) => a.id - b.id,
								);
							})
						}
					>
						{/* <option value={LegalEntity.MORAL}>MORALE</option> */}
						<option value={LegalEntity.PHYSICAL}>PHYSIQUE</option>
					</Select>
				</Box>
			</HStack>
		)}
	</VStack>
);
