import { SearchBy } from 'components/filters/SearchBy';
import useSessionStorageState from 'hooks/useSessionStorate';
import { Partner } from 'pages/ops/super/blocking-instance/BlockingInstanceCreate';
import { BlockingInstanceLifecycle, BlockingInstanceStatus } from 'types/blocking-instance.type';

const initialState = {
	partnerFilter: Object.values(Partner),
	statusFilter: Object.values(BlockingInstanceStatus),
	lifecycleFilter: Object.values(BlockingInstanceLifecycle),
	onlyOverdue: false,
	input: '',
	searchBy: 'email' as const,
	toBeCalled: false,
};

const useBlockingInstanceFilters = (context: string, customSearchBy?: SearchBy, customInput?: string) => {
	const [partnerFilter, setPartnerFilter] = useSessionStorageState<string[]>(
		`${context}_BLOCKING_INSTANCE_PARTNER_FILTER`,
		initialState.partnerFilter,
	);
	const [statusFilter, setStatusFilter] = useSessionStorageState<BlockingInstanceStatus[]>(
		`${context}_BLOCKING_INSTANCE_STATUS_FILTER`,
		initialState.statusFilter,
	);
	const [lifecycleFilter, setLifecycleFilter] = useSessionStorageState<BlockingInstanceLifecycle[]>(
		`${context}_BLOCKING_INSTANCE_LIFECYCLE_FILTER`,
		initialState.lifecycleFilter,
	);
	const [onlyOverdue, setOnlyOverdue] = useSessionStorageState<boolean>(
		`${context}_BLOCKING_INSTANCE_ONLY_OVERDUE`,
		initialState.onlyOverdue,
	);
	const [input, setInput] = useSessionStorageState(
		`${context}_BLOCKING_INSTANCE_SEARCH_INPUT`,
		customInput ?? initialState.input,
	);
	const [searchBy, setSearchBy] = useSessionStorageState<SearchBy>(
		`${context}_BLOCKING_INSTANCE_SEARCH_BY`,
		customSearchBy ?? initialState.searchBy,
	);
	const [toBeCalled, setToBeCalled] = useSessionStorageState<boolean>(
		`${context}_BLOCKING_INSTANCE_TO_BE_CALLED`,
		initialState.toBeCalled,
	);

	const resetFilters = () => {
		setStatusFilter(initialState.statusFilter);
		setLifecycleFilter(initialState.lifecycleFilter);
		setPartnerFilter(initialState.partnerFilter);
		setOnlyOverdue(initialState.onlyOverdue);
		setInput(initialState.input);
		setSearchBy(initialState.searchBy);
		setToBeCalled(false);
	};

	return {
		partnerFilter,
		setPartnerFilter,
		statusFilter,
		setStatusFilter,
		lifecycleFilter,
		setLifecycleFilter,
		onlyOverdue,
		setOnlyOverdue,
		searchBy,
		setSearchBy,
		input,
		setInput,
		toBeCalled,
		setToBeCalled,
		resetFilters,
	};
};

export default useBlockingInstanceFilters;
