import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { useAuth } from '@frontegg/react';

import { ClientIcon, DealIcon, SubscriptionIcon } from 'assets/icons';
import CalendarCpm from 'pages/cpm/super/calendar';
import PropaleCpm from 'pages/cpm/super/propale';
import { DrawerButton } from 'pages/ops/super';
import ClientRouter from 'pages/ops/super/client';
import { useGetCalendarQuery } from 'services/cpm/calendar';
import { useGetPropalesQuery } from 'services/cpm/propale';
import { PropaleStatus } from 'types/cpm-propale';
import { BoPermission } from 'utils/permissions';

const SuperToolLayout = () => {
	const location = useLocation();

	const { user } = useAuth();

	const { data: calendarEvents } = useGetCalendarQuery('day');
	const { data: propales } = useGetPropalesQuery(
		{ input: '', searchBy: 'email', assignedCpmEmail: user?.email },
		{ skip: !user },
	);

	return (
		<HStack h="calc(100vh - 65px)" w="100%" spacing="0px">
			<VStack
				h="100%"
				w="250px"
				py="24px"
				px="8px"
				alignSelf="start"
				spacing="4px"
				bg="white"
				borderRightWidth="1px"
				borderColor="grey.200"
			>
				<DrawerButton
					path="client"
					permission={BoPermission.CLIENT_READ}
					location={location}
					icon={<ClientIcon boxSize="24px" />}
				>
					Client
				</DrawerButton>
				<DrawerButton
					path="meeting"
					permission={BoPermission.CALENDAR_READ}
					location={location}
					icon={<SubscriptionIcon boxSize="24px" />}
					amount={calendarEvents?.length}
				>
					Meetings
				</DrawerButton>
				<DrawerButton
					path="propale"
					permission={BoPermission.PROPALE_READ}
					location={location}
					icon={<DealIcon boxSize="24px" />}
					amount={propales?.filter((p) => p.status === PropaleStatus.TODO).length}
				>
					Propales
				</DrawerButton>
			</VStack>

			<Box h="100%" w="calc(100% - 250px)" px="32px" py="24px" bg="grey.50">
				<Outlet />
			</Box>
		</HStack>
	);
};

const SuperToolRouter = (): JSX.Element => (
	<Routes>
		<Route element={<SuperToolLayout />}>
			<Route path="client/*" element={<ClientRouter basePath="cpm" />} />
			<Route path="meeting" element={<CalendarCpm />} />
			<Route path="propale/*" element={<PropaleCpm />} />
			<Route path="*" element={<Navigate to="/cpm/super/client" replace />} />
		</Route>
	</Routes>
);

export default SuperToolRouter;
