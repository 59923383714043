import { Button, Divider, Heading, HStack, Skeleton, Text, useToast, VStack } from '@chakra-ui/react';

import DictDisplayTable from 'components/tables/DictDisplayTable';
import { useGetClientKYCQuery, useLazyGetAutologinTokenQuery } from 'services/client';
import { useGetUserAvisImpositionQuery } from 'services/cpm/fiscality-propale';
import { User } from 'types/user.type';
import { ramifyStacks } from 'utils/constants';
import { fieldTranslation } from 'utils/fieldTranslation';
import { sleep } from 'utils/functions';

export type NotionProposalReviewInfosClientProps = {
	user: User;
	onNext: () => void;
	onPrev: () => void;
};

const NotionProposalReviewInfosClient = ({ user, onNext, onPrev }: NotionProposalReviewInfosClientProps) => {
	const toast = useToast();

	const { data: userKyc, refetch, isFetching } = useGetClientKYCQuery({ email: user.email });
	const {
		data: avisImpot,
		refetch: refetchAvisImpot,
		isFetching: isAvisImpotFetching,
	} = useGetUserAvisImpositionQuery(user.id);
	const [getAutologin] = useLazyGetAutologinTokenQuery();

	const handleAutologin = async () => {
		getAutologin({ email: user.email })
			.unwrap()
			.then(async (res) => {
				toast({ status: 'success', title: 'Utilisateur trouvé' });
				await sleep(1000);
				window.open(`${ramifyStacks.ramifyAppUrl.toString()}autologin?autologinToken=${res.token}`, '_blank');
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};
	return (
		<VStack w="100%" spacing="24px" align="start">
			<Divider />
			<HStack w="100%" spacing="12px">
				<Text>Vous êtes sur le compte de :</Text>
				<Heading size="sm">{user.email}</Heading>
			</HStack>
			<Divider />
			<Button w="100%" onClick={handleAutologin}>
				Autologin sur le compte
			</Button>
			<Divider />
			{userKyc ? (
				<>
					<HStack w="100%" spacing="24px">
						<Heading size="sm">Récapitulatif des informations de ce client</Heading>
						<Button
							size="sm"
							colorScheme="blue"
							onClick={() => {
								refetch();
								refetchAvisImpot();
								toast({ status: 'success', title: 'Les infos ont été rafraichies' });
							}}
							isLoading={isFetching || isAvisImpotFetching}
						>
							Rafraichir
						</Button>
					</HStack>
					<Divider />
					{avisImpot ? (
						<HStack w="100%" spacing="24px">
							<Heading size="sm">Avis d'imposition</Heading>
							<Button
								onClick={() => {
									window.open(avisImpot.url, '_blank');
								}}
							>
								Voir
							</Button>
						</HStack>
					) : (
						<Text>Aucun avis d'imposition pour ce client</Text>
					)}
					<Divider />
					<DictDisplayTable
						dict={userKyc.kyc}
						fieldToRemove={['userId', 'taxNoticeFileId']}
						translation={fieldTranslation}
					/>
				</>
			) : (
				<Skeleton w="100%" h="200px" />
			)}
			<Divider />
			<HStack w="100%" justify="space-between">
				<Button onClick={onPrev}>Précédent</Button>
				<Button colorScheme="blue" onClick={onNext}>
					Suivant
				</Button>
			</HStack>
		</VStack>
	);
};

export default NotionProposalReviewInfosClient;
