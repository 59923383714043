import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { Button, HStack, Input, Select, Skeleton } from '@chakra-ui/react';

import Cardlayout from 'components/CardLayout';
import FilterPopovers from 'components/filters';
import DocumentsTable from 'components/tables/DocumentsTable';
import useThemedToast from 'hooks/useThemedToast';
import { useGetClientByEmailQuery } from 'services/client';
import { AllDeal, OpsInvestSub, OpsTransferDeal } from 'services/deal';
import { RamifyDoc, useGetDocumentsQuery, useUploadDocumentMutation } from 'services/document';
import { useLazyGetSubscriptionsQuery } from 'services/subscription';
import { BOContext, ProductType, SubscriptionStatus } from 'types/global.type';
import { documentDataMap, DocumentName, S3Folder } from 'utils/documentNamingMap';
import { isNone, isNotNone } from 'utils/functions';

type DocumentsListProps = {
	email: string;
} & (
	| {
			context: Extract<BOContext, 'client'>;
			subscription?: never;
			selectedDocuments?: never;
			setSelectedDocuments?: never;
	  }
	| {
			context: Extract<BOContext, 'subscription'>;
			subscription: AllDeal;
			selectedDocuments: RamifyDoc[];
			setSelectedDocuments: (d: RamifyDoc[]) => void;
	  }
	| {
			context: Extract<BOContext, 'deal'>;
			subscription: AllDeal;
			selectedDocuments?: never;
			setSelectedDocuments?: never;
	  }
);

const DocumentsList: FC<DocumentsListProps> = ({
	context,
	email,
	subscription,
	selectedDocuments,
	setSelectedDocuments,
}) => {
	const toast = useThemedToast();

	const { data: client } = useGetClientByEmailQuery(email);
	const { data: documents, isFetching: isDocumentsFetching } = useGetDocumentsQuery(
		{
			email: email,
			productType: ['deal', 'subscription'].includes(context) ? subscription?.productType : undefined,
			subscriptionId: ['deal', 'subscription'].includes(context) ? subscription?.id : undefined,
		},
		{ refetchOnFocus: true, refetchOnReconnect: true, pollingInterval: 300000 },
	);
	const [getSubscriptions] = useLazyGetSubscriptionsQuery();
	const [uploadDocument, { isLoading: isUploadLoading }] = useUploadDocumentMutation();

	const inputFile = useRef<HTMLInputElement | null>(null);
	const [fileToUpload, setFileToUpload] = useState<FileList | null>(null);
	const [documentName, setDocumentName] = useState(DocumentName.JUSTIFICATIF_DOMICILE);
	const [documentFolder, setDocumentFolder] = useState(S3Folder.PJ);

	const [filterDocumentName, setFilterDocumentName] = useState('');
	const [filterFolder, setFilterFolder] = useState<string[]>([]);
	const [filterExtension, setFilterExtension] = useState<string[]>([]);

	const folderOptions = useMemo(() => [...new Set(documents?.map((d) => d.folder))].sort(), [documents]);
	const extensionOptions = useMemo(() => [...new Set(documents?.map((d) => d.contentType ?? ''))].sort(), [documents]);

	const resetFileInput = useCallback(() => {
		setFileToUpload(null);
		inputFile.current!.value = '';
	}, []);

	const handleFileUpload = useCallback(
		async (files: FileList) => {
			const formData = new FormData();
			formData.append('file', files[0]);
			formData.append('userId', client!.id);
			formData.append('documentName', documentName);

			if (context !== 'client') {
				formData.append('productType', subscription.productType);
				formData.append('subscriptionId', subscription.id);
				if (subscription && 'moralPersonId' in subscription && subscription.moralPersonId)
					formData.append('moralPersonId', subscription.moralPersonId);
				if (subscription && 'bankInformationId' in subscription && subscription.bankInformationId)
					formData.append('bankInformationId', subscription.bankInformationId);
			}

			await uploadDocument(formData)
				.unwrap()
				.catch((e) => toast({ title: 'Erreur', description: e.data.message, status: 'error' }));
			resetFileInput();
		},
		[client, context, documentName, subscription, toast, uploadDocument, resetFileInput],
	);

	// preselect documents
	useEffect(() => {
		if (context !== 'subscription' || isNone(documents)) return;
		if (
			subscription.productType === ProductType.INVEST &&
			subscription.dealType !== 'TRANSFER' &&
			(subscription as OpsInvestSub).envelope.type === 'PER'
		) {
			setSelectedDocuments(
				documents.filter(async (doc) => {
					if (doc.folder !== 'per-transfer') return;
					const subs = await getSubscriptions({ searchBy: 'email', input: email }).unwrap();
					const transferId = doc.url.slice(doc.url.indexOf('per-transfer')).split('/')[1];
					const transferRequest = subs?.find((s) => s.id === transferId);
					if (isNone(transferRequest)) return;
					return (transferRequest as OpsTransferDeal).status === SubscriptionStatus.SIGNED;
				}),
			);
			return;
		}
	}, [context, documents, email, getSubscriptions, setSelectedDocuments, subscription]);

	// update default document to upload when upload folder changes
	useEffect(() => {
		setDocumentName(
			Object.keys(documentDataMap).find(
				(key) => documentDataMap[key as DocumentName].folder === documentFolder,
			) as DocumentName,
		);
	}, [documentFolder]);

	return (
		<Cardlayout title="Documents">
			<HStack w="100%" mb="16px">
				<Input
					size="md"
					placeholder="Rechercher un document"
					w="350px"
					value={filterDocumentName}
					onChange={(e) => setFilterDocumentName(e.target.value)}
				/>
				<FilterPopovers
					components={[
						{
							title: 'Extension',
							componentProps: {
								value: filterExtension,
								onChange: (v: string[]) => setFilterExtension(v),
								options: extensionOptions,
							},
						},
						{
							title: 'Dossier',
							componentProps: {
								value: filterFolder,
								onChange: (v: string[]) => setFilterFolder(v),
								options: folderOptions,
							},
						},
					]}
				/>
			</HStack>
			<Skeleton isLoaded={!isDocumentsFetching} w="100%">
				<DocumentsTable
					docList={(documents ?? [])
						.filter((doc) => doc.folder !== 'TaxNotice')
						.filter((doc) => filterFolder.length === 0 || filterFolder.includes(doc.folder))
						.filter((doc) => filterExtension.length === 0 || filterExtension.includes(doc.contentType ?? ''))
						.filter((doc) =>
							(documentDataMap[doc.documentName]?.displayName ?? doc.documentName)
								.toLocaleLowerCase()
								.includes(filterDocumentName.toLocaleLowerCase()),
						)}
					selectedDocs={selectedDocuments}
					setSelectedDocs={setSelectedDocuments}
					hideSelect={context !== 'subscription'}
				/>
			</Skeleton>

			<Input type="file" ref={inputFile} display="none" onChange={(e) => setFileToUpload(e.target.files)} />
			<HStack mt="16px">
				<Button onClick={() => inputFile.current?.click()}>Ajouter un document</Button>
				<Select value={documentFolder} onChange={(e) => setDocumentFolder(e.target.value as S3Folder)} w="350px">
					<option value={S3Folder.PJ}>Pièce justificative</option>
					{['deal', 'subscription'].includes(context) && (
						<option value={S3Folder.SUBSCRIPTION}>Document lié à la souscription</option>
					)}
					{['deal', 'subscription'].includes(context) && (
						<option value={S3Folder.BANK_ACCOUNT}>Document bancaire</option>
					)}
					{['deal', 'subscription'].includes(context) &&
						isNotNone(subscription) &&
						'moralPersonId' in subscription &&
						isNotNone(subscription.moralPersonId) && (
							<option value={S3Folder.MORAL_PERSON}>Document personne morale</option>
						)}
				</Select>
				<Select value={documentName} onChange={(e) => setDocumentName(e.target.value as DocumentName)} w="350px">
					{Object.entries(documentDataMap)
						.filter(([, value]) => value.folder === documentFolder)
						.map(([key, value]) => (
							<option key={key} value={key}>
								{value.displayName}
							</option>
						))}
				</Select>

				{isNotNone(fileToUpload) && (
					<>
						<Button colorScheme="blue" isLoading={isUploadLoading} onClick={() => handleFileUpload(fileToUpload)}>
							Uploader {fileToUpload[0].name ?? ''}
						</Button>
						<CloseIcon cursor="pointer" boxSize="12px" onClick={() => resetFileInput()} />
					</>
				)}
			</HStack>
		</Cardlayout>
	);
};

export default DocumentsList;
