import { useState } from 'react';
import { CopyIcon } from '@chakra-ui/icons';
import {
	Button,
	Divider,
	Heading,
	HStack,
	Input,
	Radio,
	RadioGroup,
	Skeleton,
	Text,
	useToast,
	VStack,
} from '@chakra-ui/react';

import SimpleClientsTable from 'components/tables/SimpleClientTable';
import ClientList from 'pages/ops/super/client/ClientList';
import { UserWithKycAndMoral } from 'services/client';
import {
	OnboardedUser,
	TestAccount,
	useCreateTestAccountMutation,
	useGetClientsWhoDidPropalQuery,
} from 'services/cpm/fiscality-propale';
import { User } from 'types/user.type';

export type NotionProposalSelectAccountProps = {
	selectedUser?: User;
	setSelectedUser: (user: User) => void;
	onNext: (user: User) => void;
};

const NotionProposalSelectAccount = ({ onNext, selectedUser, setSelectedUser }: NotionProposalSelectAccountProps) => {
	const toast = useToast();

	const [createTestAccount] = useCreateTestAccountMutation();
	const { data: clients, isFetching } = useGetClientsWhoDidPropalQuery();

	const [userCreated, setCreatedAccount] = useState<TestAccount | undefined>();
	const [userParcours, setUserParcours] = useState<OnboardedUser | null>(null);
	const [searchEmail, setSearchEmail] = useState<string>('');

	const [selectionMode, setSelectionMode] = useState<'create' | 'select' | 'parcours'>('select');

	const onCreateTestAccount = () => {
		createTestAccount()
			.unwrap()
			.then((res) => setCreatedAccount(res))
			.catch((e) => console.error(e));
	};

	const onClickNext = () => {
		if (selectionMode === 'create') onNext(userCreated!.user);
		if (selectionMode === 'parcours') onNext(userParcours!);
		if (selectionMode === 'select') onNext(selectedUser!);
	};

	return (
		<VStack w="100%" spacing="24px" align="start">
			<Divider />
			<RadioGroup onChange={(e: 'create' | 'select' | 'parcours') => setSelectionMode(e)} value={selectionMode}>
				<HStack>
					<Radio value="select">Utiliser un compte existant</Radio>
					<Radio value="create">Créer un faux compte</Radio>
					<Radio value="parcours">Liste des clients ayant fait le parcours</Radio>
				</HStack>
			</RadioGroup>
			<Divider />

			{selectionMode === 'create' && (
				<VStack align="start" w="100%" spacing="16px">
					<Heading size="sm">Créer un compte client</Heading>
					<Button colorScheme="blue" onClick={onCreateTestAccount}>
						Créer un compte client
					</Button>

					{userCreated && (
						<>
							<VStack align="start" w="100%" spacing="8px">
								<Text color="green">Le compte suivant a été créé</Text>
								<HStack>
									<Input minW="320px" variant="filled" value={userCreated.user.email} disabled />
									<Button
										colorScheme="blue"
										onClick={() => {
											navigator.clipboard.writeText(userCreated.user.email);
											toast({
												title: 'Email copié',
												status: 'success',
												duration: 2000,
											});
										}}
									>
										<CopyIcon />
									</Button>
								</HStack>
							</VStack>
							<Divider />
							<HStack w="100%" justify="space-between">
								<Text />
								<Button onClick={onClickNext} colorScheme="blue">
									Suivant
								</Button>
							</HStack>
						</>
					)}
				</VStack>
			)}

			{selectionMode === 'select' && (
				<VStack align="start" w="100%" spacing="16px">
					<Heading size="sm">Sélectionnez un client :</Heading>
					<ClientList selectedClient={selectedUser as UserWithKycAndMoral} onClick={setSelectedUser} pageSize={20} />

					{selectedUser && (
						<>
							<Divider />
							<HStack w="100%" justify="space-between">
								<Text />
								<Button onClick={onClickNext} colorScheme="blue">
									Suivant
								</Button>
							</HStack>
						</>
					)}
				</VStack>
			)}

			{selectionMode === 'parcours' && clients && (
				<VStack align="start" w="100%" spacing="16px">
					<Heading size="sm">Sélectionnez un client :</Heading>

					<Skeleton isLoaded={!isFetching} w="100%">
						<SimpleClientsTable
							onEmailFilter={(e) => setSearchEmail(e)}
							clients={clients.filter((c) => c.email.includes(searchEmail))}
							selectedClient={userParcours}
							setSelectedClient={(c) => setUserParcours(c)}
						/>
					</Skeleton>

					{userParcours && (
						<>
							<Divider />
							<HStack w="100%" justify="space-between">
								<Text />
								<Button onClick={onClickNext} colorScheme="blue">
									Suivant
								</Button>
							</HStack>
						</>
					)}
				</VStack>
			)}
		</VStack>
	);
};

export default NotionProposalSelectAccount;
