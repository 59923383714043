import { KycAndAdequation, MoralPersonWithBeneficiaries } from 'services/client';
import { AllDeal } from 'services/deal';
import {
	knowledgeLevel,
	lastOperationChoices,
	lengthPerceptionOptions,
	riskPerceptionOptions,
} from 'types/adequation.type';
import { BankInformation } from 'types/bank-information.type';
import { ProductType } from 'types/global.type';
import { primaryResidenceOptions, StudyLvl, WorkLvl } from 'types/kyc.type';
import { FundOriginDocumentTypeOptions, FundOriginOptions } from 'types/moralPerson.type';
import CivilStatusReferentiel from 'types/referentiels/civilStatus';
import FamilySituationReferentiel from 'types/referentiels/familySituation';
import IncomeOriginReferentiel from 'types/referentiels/incomeOrigin';
import IncomePeriodReferentiel from 'types/referentiels/incomePeriod';
import IncomeTypeReferentiel from 'types/referentiels/incomeType';
import LegalCapacityReferentiel from 'types/referentiels/legalCapacity';
import MatrimonialRegimeReferentiel from 'types/referentiels/matrimonial';
import patrimonySourceReferentiel from 'types/referentiels/patrimonySource';
import ProfessionalCategoryReferentiel from 'types/referentiels/professionalCategory';
import ProfessionalSectorReferentiel from 'types/referentiels/professionalSector';
import ProfessionalSituationReferentiel from 'types/referentiels/professionalSituation';
import { fieldTranslation } from 'utils/fieldTranslation';

type DisplayUserInfoProps = {
	kycAdequationHistoriqueFiscale?: KycAndAdequation;
	moralPersonOfSubscription?: MoralPersonWithBeneficiaries;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	subscriptionDataTodisplay: Record<string, any>;
	subscription?: AllDeal;
	BankAccountOfSubscription?: BankInformation;
};

const generateUserInfoPayload = ({
	kycAdequationHistoriqueFiscale,
	moralPersonOfSubscription,
	subscriptionDataTodisplay,
	subscription,
	BankAccountOfSubscription,
}: DisplayUserInfoProps) => ({
	Kyc: [
		{ key: fieldTranslation.lastName, value: kycAdequationHistoriqueFiscale?.kyc?.lastName },
		{ key: fieldTranslation.firstName, value: kycAdequationHistoriqueFiscale?.kyc?.firstName },
		{
			key: fieldTranslation.civilStatus,
			value: kycAdequationHistoriqueFiscale?.kyc?.civilStatus
				? CivilStatusReferentiel[kycAdequationHistoriqueFiscale?.kyc?.civilStatus]
				: '',
		},
		{
			key: fieldTranslation.familySituation,
			value: kycAdequationHistoriqueFiscale?.kyc?.familySituation
				? FamilySituationReferentiel[kycAdequationHistoriqueFiscale?.kyc?.familySituation]
				: '',
		},
		{
			key: fieldTranslation.matrimonialRegime,
			value: kycAdequationHistoriqueFiscale?.kyc?.matrimonialRegime
				? MatrimonialRegimeReferentiel[kycAdequationHistoriqueFiscale?.kyc?.matrimonialRegime]
				: '',
		},
		{
			key: fieldTranslation.legalCapacity,
			value: kycAdequationHistoriqueFiscale?.kyc?.legalCapacity
				? LegalCapacityReferentiel[kycAdequationHistoriqueFiscale?.kyc?.legalCapacity]
				: '',
		},
		{ key: fieldTranslation.birthName, value: kycAdequationHistoriqueFiscale?.kyc?.birthName },
		{
			key: fieldTranslation.birthDate,
			value: kycAdequationHistoriqueFiscale?.kyc?.birthDate,
		},
		{ key: fieldTranslation.birthCity, value: kycAdequationHistoriqueFiscale?.kyc?.birthCity },
		{ key: fieldTranslation.birthCityCode, value: kycAdequationHistoriqueFiscale?.kyc?.birthCityCode },
		{ key: fieldTranslation.birthZipCode, value: kycAdequationHistoriqueFiscale?.kyc?.birthZipCode },
		{ key: fieldTranslation.birthCountry, value: kycAdequationHistoriqueFiscale?.kyc?.birthCountry },
		{ key: fieldTranslation.address, value: kycAdequationHistoriqueFiscale?.kyc?.address },
		{ key: fieldTranslation.city, value: kycAdequationHistoriqueFiscale?.kyc?.city },
		{ key: fieldTranslation.zipCode, value: kycAdequationHistoriqueFiscale?.kyc?.zipCode },
		{ key: fieldTranslation.country, value: kycAdequationHistoriqueFiscale?.kyc?.country },
		{
			key: fieldTranslation.phoneNumberCode,
			value: kycAdequationHistoriqueFiscale?.kyc?.phoneNumberCode,
		},
		{ key: fieldTranslation.phoneNumber, value: kycAdequationHistoriqueFiscale?.kyc?.phoneNumber },
		{
			key: fieldTranslation.professionalSector,
			value: kycAdequationHistoriqueFiscale?.kyc?.professionalSector
				? ProfessionalSectorReferentiel[kycAdequationHistoriqueFiscale?.kyc?.professionalSector]
				: '',
		},

		{
			key: fieldTranslation.professionalCategory,
			value: kycAdequationHistoriqueFiscale?.kyc?.professionalCategory
				? ProfessionalCategoryReferentiel[kycAdequationHistoriqueFiscale?.kyc?.professionalCategory]
				: '',
		},
		{
			key: fieldTranslation.professionalSituation,
			value: kycAdequationHistoriqueFiscale?.kyc?.professionalSituation
				? ProfessionalSituationReferentiel[kycAdequationHistoriqueFiscale?.kyc?.professionalSituation]
				: '',
		},
		{ key: fieldTranslation.companyName, value: kycAdequationHistoriqueFiscale?.kyc?.companyName },
		{ key: fieldTranslation.profession, value: kycAdequationHistoriqueFiscale?.kyc?.profession },
		{ key: fieldTranslation.selfEmployed, value: kycAdequationHistoriqueFiscale?.kyc?.selfEmployed },
		{ key: fieldTranslation.siret, value: kycAdequationHistoriqueFiscale?.kyc?.siret },
		{ key: fieldTranslation.nif, value: kycAdequationHistoriqueFiscale?.kyc?.nif },
		{ key: fieldTranslation.nir, value: kycAdequationHistoriqueFiscale?.kyc?.nir },
		{
			key: fieldTranslation.franceFiscalResident,
			value: kycAdequationHistoriqueFiscale?.kyc?.franceFiscalResident,
		},
		{
			key: fieldTranslation.notOtherFiscalResident,
			value: kycAdequationHistoriqueFiscale?.kyc?.notOtherFiscalResident,
		},
		{
			key: fieldTranslation.otherCountryFiscalResident,
			value: kycAdequationHistoriqueFiscale?.kyc?.otherCountryFiscalResident,
		},
		{
			key: fieldTranslation.otherCountryFiscalNif,
			value: kycAdequationHistoriqueFiscale?.kyc?.otherCountryFiscalNif,
		},

		{ key: fieldTranslation.fiscalAddress, value: kycAdequationHistoriqueFiscale?.kyc?.fiscalAddress },
		{
			key: fieldTranslation.fiscalAddressCity,
			value: kycAdequationHistoriqueFiscale?.kyc?.fiscalAddressCity,
		},
		{
			key: fieldTranslation.fiscalAddressZipCode,
			value: kycAdequationHistoriqueFiscale?.kyc?.fiscalAddressZipCode,
		},

		{ key: fieldTranslation.firstNameSpouse, value: kycAdequationHistoriqueFiscale?.kyc?.firstNameSpouse },
		{ key: fieldTranslation.lastNameSpouse, value: kycAdequationHistoriqueFiscale?.kyc?.lastNameSpouse },
		{ key: fieldTranslation.professionSpouse, value: kycAdequationHistoriqueFiscale?.kyc?.professionSpouse },
		{ key: fieldTranslation.childNumber, value: kycAdequationHistoriqueFiscale?.kyc?.childNumber },
		{
			key: fieldTranslation.hasExercisedFinancialProfession,
			value: kycAdequationHistoriqueFiscale?.kyc?.hasExercisedFinancialProfession
				? WorkLvl.filter(([knowledgeKey]) =>
						kycAdequationHistoriqueFiscale?.kyc?.hasExercisedFinancialProfession.includes(knowledgeKey),
				  )[0][1]
				: '',
		},
		{
			key: fieldTranslation.hasStudiedInFinance,
			value: kycAdequationHistoriqueFiscale?.kyc?.hasStudiedInFinance
				? StudyLvl.map(([knowledgeKey, knowledgeDescription]) => ({
						key: knowledgeKey,
						value: knowledgeDescription,
				  }))[0].value
				: '',
		},

		{ key: fieldTranslation.isPoliticallyExposed, value: kycAdequationHistoriqueFiscale?.kyc?.isPoliticallyExposed },
		{ key: fieldTranslation.isUSPerson, value: kycAdequationHistoriqueFiscale?.kyc?.isUSPerson },
	],
	'Revenus et patrimoine': [
		{ title: 'Revenus du foyer' },
		{ key: fieldTranslation.revenu, value: kycAdequationHistoriqueFiscale?.kyc?.revenu },
		{
			key: fieldTranslation.revenuType,
			value: kycAdequationHistoriqueFiscale?.kyc?.revenuType
				? IncomeTypeReferentiel[kycAdequationHistoriqueFiscale?.kyc?.revenuType]
				: '',
		},
		{
			key: fieldTranslation.revenuPeriod,
			value: kycAdequationHistoriqueFiscale?.kyc?.revenuPeriod
				? IncomePeriodReferentiel[kycAdequationHistoriqueFiscale?.kyc?.revenuPeriod]
				: '',
		},
		{
			key: IncomeOriginReferentiel.PROFESSIONAL_INCOME,
			value: kycAdequationHistoriqueFiscale?.kyc?.revenuOrigin?.PROFESSIONAL_INCOME,
		},
		{
			key: IncomeOriginReferentiel.PROPERTY_INCOME,
			value: kycAdequationHistoriqueFiscale?.kyc?.revenuOrigin?.PROPERTY_INCOME,
		},
		{
			key: IncomeOriginReferentiel.RETIREMENT_INCOME,
			value: kycAdequationHistoriqueFiscale?.kyc?.revenuOrigin?.RETIREMENT_INCOME,
		},
		{
			key: IncomeOriginReferentiel.SECURITIES_INCOME,
			value: kycAdequationHistoriqueFiscale?.kyc?.revenuOrigin?.SECURITIES_INCOME,
		},

		{ key: fieldTranslation.revenuSpouse, value: kycAdequationHistoriqueFiscale?.kyc?.revenuSpouse },
		{ key: fieldTranslation.revenuSpouseType, value: kycAdequationHistoriqueFiscale?.kyc?.revenuSpouseType },
		{
			key: fieldTranslation.revenuSpousePeriod,
			value: kycAdequationHistoriqueFiscale?.kyc?.revenuSpousePeriod
				? IncomePeriodReferentiel[kycAdequationHistoriqueFiscale?.kyc?.revenuSpousePeriod]
				: '',
		},

		{
			key: IncomeOriginReferentiel.PROFESSIONAL_INCOME + ' conjoint',
			value: kycAdequationHistoriqueFiscale?.kyc?.revenuSpouseOrigin?.PROFESSIONAL_INCOME,
		},
		{
			key: IncomeOriginReferentiel.PROPERTY_INCOME + ' conjoint',
			value: kycAdequationHistoriqueFiscale?.kyc?.revenuSpouseOrigin?.PROPERTY_INCOME,
		},
		{
			key: IncomeOriginReferentiel.RETIREMENT_INCOME + ' conjoint',
			value: kycAdequationHistoriqueFiscale?.kyc?.revenuSpouseOrigin?.RETIREMENT_INCOME,
		},
		{
			key: IncomeOriginReferentiel.SECURITIES_INCOME + ' conjoint',
			value: kycAdequationHistoriqueFiscale?.kyc?.revenuSpouseOrigin?.SECURITIES_INCOME,
		},

		{ title: 'Patrimoine du foyer' },
		{
			key: 'Patrimoine financier total',
			value:
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimateBankAccount ?? 0) +
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimatePortfolio ?? 0) +
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimateProfessionalAssets ?? 0) +
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimateCcontractAVCapitalisation ?? 0) +
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimateRetirement ?? 0) +
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimateOther ?? 0) +
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyNonCote ?? 0),
		},

		{
			key: 'Patrimoine immobilier total ',
			value:
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyRealEstate ?? 0) +
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimatePrimaryRealEstate ?? 0) +
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimateSecondaryRealEstate ?? 0),
		},
		{
			key: 'Patrimoine global',
			value:
				//Patrimoine immobilier total
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyRealEstate ?? 0) +
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimatePrimaryRealEstate ?? 0) +
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimateSecondaryRealEstate ?? 0) +
				//patrimoine financier total
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimateBankAccount ?? 0) +
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimatePortfolio ?? 0) +
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimateProfessionalAssets ?? 0) +
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimateCcontractAVCapitalisation ?? 0) +
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimateRetirement ?? 0) +
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimateOther ?? 0) +
				(kycAdequationHistoriqueFiscale?.kyc?.patrimonyNonCote ?? 0),
		},

		{ key: fieldTranslation.patrimonyRealEstate, value: kycAdequationHistoriqueFiscale?.kyc?.patrimonyRealEstate },

		{
			key: fieldTranslation.primaryResidenceStatus,
			value: kycAdequationHistoriqueFiscale?.kyc?.primaryResidenceStatus
				? primaryResidenceOptions.map(([, display]) => display)[
						primaryResidenceOptions.findIndex(
							([key]) => key === kycAdequationHistoriqueFiscale?.kyc?.primaryResidenceStatus,
						)
				  ]
				: '',
		},
		{
			key: fieldTranslation.patrimonyEstimatePrimaryRealEstate,
			value: kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimatePrimaryRealEstate,
		},
		{
			key: fieldTranslation.patrimonyEstimateSecondaryRealEstate,
			value: kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimateSecondaryRealEstate,
		},
		{
			key: fieldTranslation.patrimonyEstimateBankAccount,
			value: kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimateBankAccount,
		},
		{
			key: fieldTranslation.patrimonyEstimateProfessionalAssets,
			value: kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimateProfessionalAssets,
		},
		{
			key: fieldTranslation.patrimonyEstimateOther,
			value: kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimateOther,
		},
		{
			key: fieldTranslation.patrimonySources,
			value: kycAdequationHistoriqueFiscale?.kyc?.patrimonySources
				.map((data) => patrimonySourceReferentiel[data])
				.toString(),
		},
		{
			key: fieldTranslation.patrimonyEstimatePortfolio,
			value: kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimatePortfolio,
		},
		{
			key: fieldTranslation.patrimonyEstimateRetirement,
			value: kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimateRetirement,
		},
		{
			key: fieldTranslation.patrimonyEstimateCcontractAVCapitalisation,
			value: kycAdequationHistoriqueFiscale?.kyc?.patrimonyEstimateCcontractAVCapitalisation,
		},
		{ key: fieldTranslation.patrimonyNonCote, value: kycAdequationHistoriqueFiscale?.kyc?.patrimonyNonCote },
		{ title: 'Emprunts et crédits' },

		{
			key: fieldTranslation.realEstateLoanFrequency,
			value: kycAdequationHistoriqueFiscale?.kyc?.realEstateLoanFrequency,
		},
		{ key: fieldTranslation.realEstateLoanAmount, value: kycAdequationHistoriqueFiscale?.kyc?.realEstateLoanAmount },
		{ key: fieldTranslation.realEstateLoanRest, value: kycAdequationHistoriqueFiscale?.kyc?.realEstateLoanRest },
		{ key: fieldTranslation.hasRealEstateLoan, value: kycAdequationHistoriqueFiscale?.kyc?.hasRealEstateLoan },

		{ key: fieldTranslation.hasOtherLoan, value: kycAdequationHistoriqueFiscale?.kyc?.hasOtherLoan },
		{ key: fieldTranslation.otherLoanFrequency, value: kycAdequationHistoriqueFiscale?.kyc?.otherLoanFrequency },
		{ key: fieldTranslation.otherLoanAmount, value: kycAdequationHistoriqueFiscale?.kyc?.otherLoanAmount },
		{ key: fieldTranslation.otherLoanRest, value: kycAdequationHistoriqueFiscale?.kyc?.otherLoanRest },

		{ key: fieldTranslation.hasConsomationLoan, value: kycAdequationHistoriqueFiscale?.kyc?.hasConsomationLoan },
		{
			key: fieldTranslation.consomationLoanFrequency,
			value: kycAdequationHistoriqueFiscale?.kyc?.consomationLoanFrequency,
		},
		{
			key: fieldTranslation.consomationLoanAmount,
			value: kycAdequationHistoriqueFiscale?.kyc?.consomationLoanAmount,
		},
		{ key: fieldTranslation.consomationLoanRest, value: kycAdequationHistoriqueFiscale?.kyc?.consomationLoanRest },

		{ key: fieldTranslation.savingCapacity, value: kycAdequationHistoriqueFiscale?.kyc?.savingCapacity },

		{
			key: fieldTranslation.hasActionsOrObligations,
			value: kycAdequationHistoriqueFiscale?.kyc?.hasActionsOrObligations,
		},
		{ key: fieldTranslation.hasPEShares, value: kycAdequationHistoriqueFiscale?.kyc?.hasPEShares },
		{ key: fieldTranslation.PESharesNames, value: kycAdequationHistoriqueFiscale?.kyc?.PESharesNames },
		{ key: fieldTranslation.hasPELinks, value: kycAdequationHistoriqueFiscale?.kyc?.hasPELinks },
		{ key: fieldTranslation.PELinksNames, value: kycAdequationHistoriqueFiscale?.kyc?.PELinksNames },
		{ key: fieldTranslation.PELinksTypes, value: kycAdequationHistoriqueFiscale?.kyc?.PELinksTypes },

		{ key: fieldTranslation.taxType, value: kycAdequationHistoriqueFiscale?.kyc?.taxType },
		{ key: fieldTranslation.taxAmount, value: kycAdequationHistoriqueFiscale?.kyc?.taxAmount },
		{ key: fieldTranslation.hasIFI, value: kycAdequationHistoriqueFiscale?.kyc?.hasIFI },
		{ key: fieldTranslation.taxIFIAmount, value: kycAdequationHistoriqueFiscale?.kyc?.taxIFIAmount },
		{ key: fieldTranslation.immoRevenusBrut, value: kycAdequationHistoriqueFiscale?.kyc?.immoRevenusBrut },
	],
	Adequation: [
		{
			key: fieldTranslation.nbPrivateEquityTransactions,
			value: kycAdequationHistoriqueFiscale?.kyc?.nbPrivateEquityTransactions,
		},
		{
			key: fieldTranslation.nbRealEstateTransactions,
			value: kycAdequationHistoriqueFiscale?.kyc?.nbRealEstateTransactions,
		},
		{
			key: fieldTranslation.peaKnowledge,
			value: kycAdequationHistoriqueFiscale?.adequation?.peaKnowledge
				? knowledgeLevel[kycAdequationHistoriqueFiscale?.adequation?.peaKnowledge]
				: '',
		},
		{
			key: fieldTranslation.avKnowledge,
			value: kycAdequationHistoriqueFiscale?.adequation?.avKnowledge
				? knowledgeLevel[kycAdequationHistoriqueFiscale?.adequation?.avKnowledge]
				: '',
		},
		{
			key: fieldTranslation.opcvmMonetaireKnowledge,
			value: kycAdequationHistoriqueFiscale?.adequation?.opcvmMonetaireKnowledge
				? knowledgeLevel[kycAdequationHistoriqueFiscale?.adequation?.opcvmMonetaireKnowledge]
				: '',
		},

		{
			key: fieldTranslation.opcvmActionKnowledge,
			value: kycAdequationHistoriqueFiscale?.adequation?.opcvmActionKnowledge
				? knowledgeLevel[kycAdequationHistoriqueFiscale?.adequation?.opcvmActionKnowledge]
				: '',
		},
		{
			key: fieldTranslation.nonCoteKnowledge,
			value: kycAdequationHistoriqueFiscale?.adequation?.nonCoteKnowledge
				? knowledgeLevel[kycAdequationHistoriqueFiscale?.adequation?.nonCoteKnowledge]
				: '',
		},
		{
			key: fieldTranslation.coteKnowledge,
			value: kycAdequationHistoriqueFiscale?.adequation?.coteKnowledge
				? knowledgeLevel[kycAdequationHistoriqueFiscale?.adequation?.coteKnowledge]
				: '',
		},
		{
			key: fieldTranslation.produitStrucutureKnowledge,
			value: kycAdequationHistoriqueFiscale?.adequation?.produitStrucutureKnowledge
				? knowledgeLevel[kycAdequationHistoriqueFiscale?.adequation?.produitStrucutureKnowledge]
				: '',
		},
		{
			key: fieldTranslation.obligation,
			value: kycAdequationHistoriqueFiscale?.adequation?.obligation
				? knowledgeLevel[kycAdequationHistoriqueFiscale?.adequation?.obligation]
				: '',
		},
		{
			key: fieldTranslation.scpi,
			value: kycAdequationHistoriqueFiscale?.adequation?.scpi
				? knowledgeLevel[kycAdequationHistoriqueFiscale?.adequation?.scpi]
				: '',
		},
		{
			key: fieldTranslation.opc,
			value: kycAdequationHistoriqueFiscale?.adequation?.opc
				? knowledgeLevel[kycAdequationHistoriqueFiscale?.adequation?.opc]
				: '',
		},
		{
			key: fieldTranslation.opci,
			value: kycAdequationHistoriqueFiscale?.adequation?.opci
				? knowledgeLevel[kycAdequationHistoriqueFiscale?.adequation?.opci]
				: '',
		},
		{
			key: fieldTranslation.trackers,
			value: kycAdequationHistoriqueFiscale?.adequation?.trackers
				? knowledgeLevel[kycAdequationHistoriqueFiscale?.adequation?.trackers]
				: '',
		},
		{
			key: fieldTranslation.fondEuro,
			value: kycAdequationHistoriqueFiscale?.adequation?.fondEuro
				? knowledgeLevel[kycAdequationHistoriqueFiscale?.adequation?.fondEuro]
				: '',
		},
		{
			key: fieldTranslation.lastOperation,
			value: kycAdequationHistoriqueFiscale?.adequation?.lastOperation
				? lastOperationChoices.map(([, label]) => label)[
						lastOperationChoices.findIndex(
							([name]) => name === kycAdequationHistoriqueFiscale?.adequation?.lastOperation,
						)
				  ]
				: '',
		},

		{
			key: fieldTranslation.riskPerceptionScpi,
			value: kycAdequationHistoriqueFiscale?.adequation?.riskPerceptionScpi
				? riskPerceptionOptions.map(([, label]) => label)[
						riskPerceptionOptions.findIndex(
							([name]) => name === kycAdequationHistoriqueFiscale?.adequation?.riskPerceptionScpi,
						)
				  ]
				: '',
		},
		{
			key: fieldTranslation.lengthPerceptionScpi,
			value: kycAdequationHistoriqueFiscale?.adequation?.lengthPerceptionScpi
				? lengthPerceptionOptions.map(([, label]) => label)[
						lengthPerceptionOptions.findIndex(
							([name]) => name === kycAdequationHistoriqueFiscale?.adequation?.lengthPerceptionScpi,
						)
				  ]
				: '',
		},
		{
			key: fieldTranslation.riskPerceptionPe,
			value: kycAdequationHistoriqueFiscale?.adequation?.riskPerceptionPe
				? riskPerceptionOptions.map(([, label]) => label)[
						riskPerceptionOptions.findIndex(
							([name]) => name === kycAdequationHistoriqueFiscale?.adequation?.riskPerceptionPe,
						)
				  ]
				: '',
		},
		{
			key: fieldTranslation.lengthPerceptionPe,
			value: kycAdequationHistoriqueFiscale?.adequation?.lengthPerceptionPe
				? lengthPerceptionOptions.map(([, label]) => label)[
						lengthPerceptionOptions.findIndex(
							([name]) => name === kycAdequationHistoriqueFiscale?.adequation?.lengthPerceptionPe,
						)
				  ]
				: '',
		},
		{
			key: fieldTranslation.riskPerceptionCwdfd,
			value: kycAdequationHistoriqueFiscale?.adequation?.riskPerceptionCwdfd
				? riskPerceptionOptions.map(([, label]) => label)[
						riskPerceptionOptions.findIndex(
							([name]) => name === kycAdequationHistoriqueFiscale?.adequation?.riskPerceptionCwdfd,
						)
				  ]
				: '',
		},
		{
			key: fieldTranslation.lengthPerceptionCwdfd,
			value: kycAdequationHistoriqueFiscale?.adequation?.lengthPerceptionCwdfd
				? lengthPerceptionOptions.map(([, label]) => label)[
						lengthPerceptionOptions.findIndex(
							([name]) => name === kycAdequationHistoriqueFiscale?.adequation?.lengthPerceptionCwdfd,
						)
				  ]
				: '',
		},
		{
			key: fieldTranslation.riskPerceptionArt,
			value: kycAdequationHistoriqueFiscale?.adequation?.riskPerceptionArt
				? riskPerceptionOptions.map(([, label]) => label)[
						riskPerceptionOptions.findIndex(
							([name]) => name === kycAdequationHistoriqueFiscale?.adequation?.riskPerceptionArt,
						)
				  ]
				: '',
		},
		{
			key: fieldTranslation.lengthPerceptionArt,
			value: kycAdequationHistoriqueFiscale?.adequation?.lengthPerceptionArt
				? lengthPerceptionOptions.map(([, label]) => label)[
						lengthPerceptionOptions.findIndex(
							([name]) => name === kycAdequationHistoriqueFiscale?.adequation?.lengthPerceptionArt,
						)
				  ]
				: '',
		},
	],
	...(Object.keys(subscriptionDataTodisplay).length > 0
		? {
				'Infos souscription': [
					...Object.entries(subscriptionDataTodisplay).map(([key, value]) => {
						if (typeof value === 'object' && value !== null && !Array.isArray(value)) return { key, value };
						if (Array.isArray(value)) return { key: key, value: value.join(', ') };
						return { key: key, value: fieldTranslation[value as keyof typeof fieldTranslation] || value };
					}),
					{
						key: fieldTranslation.recurrentDepositDay,
						value:
							subscriptionDataTodisplay?.subscription?.recurrentDepositDay ??
							subscriptionDataTodisplay?.recurrentDepositDay,
					},
				],
		  }
		: {}),

	...(subscription?.productType === ProductType.PE ||
	subscription?.productType === ProductType.SCPI ||
	subscription?.productType === ProductType.CASH
		? {
				'Personne morale': [
					{ key: fieldTranslation.createdAt, value: moralPersonOfSubscription?.status },
					{ key: fieldTranslation.naf, value: moralPersonOfSubscription?.naf },
					{ key: fieldTranslation.siren, value: moralPersonOfSubscription?.siren },
					{ key: fieldTranslation.activity, value: moralPersonOfSubscription?.activity },
					{ key: fieldTranslation.constitutionDate, value: moralPersonOfSubscription?.constitutionDate },
					{ key: fieldTranslation.juridicForm, value: moralPersonOfSubscription?.juridicForm },

					{
						key: fieldTranslation.legalRepresentativeFunction,
						value: moralPersonOfSubscription?.legalRepresentativeFunction,
					},
					{
						key: fieldTranslation.legalRepresentativeEntryDate,
						value: moralPersonOfSubscription?.legalRepresentativeEntryDate,
					},
					{ key: fieldTranslation.taxType, value: moralPersonOfSubscription?.taxType },
					{
						key: fieldTranslation.nonFinancialEntityType,
						value: moralPersonOfSubscription?.nonFinancialEntityType,
					},
					{ key: fieldTranslation.siegeAddress, value: moralPersonOfSubscription?.siegeAddress },
					{ key: fieldTranslation.siegeAddressDetails, value: moralPersonOfSubscription?.siegeAddressDetails },
					{ key: fieldTranslation.siegeCity, value: moralPersonOfSubscription?.siegeCity },
					{ key: fieldTranslation.correspondanceAddress, value: moralPersonOfSubscription?.correspondanceAddress },
					{
						key: fieldTranslation.correspondanceAddressDetails,
						value: moralPersonOfSubscription?.correspondanceAddressDetails,
					},
					{ key: fieldTranslation.correspondanceCity, value: moralPersonOfSubscription?.correspondanceCity },
					{
						key: fieldTranslation.correspondanceCityCode,
						value: moralPersonOfSubscription?.correspondanceCityCode,
					},
					{ key: fieldTranslation.registrationCity, value: moralPersonOfSubscription?.registrationCity },
					{
						key: fieldTranslation.hasTurnoverFromExportation,
						value: moralPersonOfSubscription?.hasTurnoverFromExportation,
					},
					{
						key: fieldTranslation.exportationCountries,
						value: moralPersonOfSubscription?.exportationCountries.map((data) => data).toString(),
					},
					{
						key: fieldTranslation.immatriculationCountry,
						value: moralPersonOfSubscription?.immatriculationCountry,
					},
					{
						key: fieldTranslation.fundOriginDocument,
						value: moralPersonOfSubscription?.fundOriginDocument
							? FundOriginDocumentTypeOptions.map(([, label]) => label)[
									FundOriginDocumentTypeOptions.findIndex(
										([name]) => name === moralPersonOfSubscription?.fundOriginDocument,
									)
							  ]
							: '',
					},

					{
						key: fieldTranslation.fundOrigin,
						value: moralPersonOfSubscription?.fundOrigin
							? FundOriginOptions.map(([, label]) => label)[
									FundOriginOptions.findIndex(([name]) => name === moralPersonOfSubscription?.fundOrigin)
							  ]
							: '',
					},
					{ key: fieldTranslation.fundOriginDetails, value: moralPersonOfSubscription?.fundOriginDetails },
					{ key: fieldTranslation.Turnover, value: moralPersonOfSubscription?.turnover },
					{ key: fieldTranslation.netIncome, value: moralPersonOfSubscription?.netIncome },
					{ key: fieldTranslation.Treasury, value: moralPersonOfSubscription?.treasury },
					{
						key: fieldTranslation.moralPersonBeneficiaries,
						value: moralPersonOfSubscription?.moralPersonBeneficiaries
							.map(
								(data) =>
									'Prenom: ' +
									data.firstName +
									' ' +
									'Nom: ' +
									data.lastName +
									' ' +
									'Pourcentage: ' +
									data.detentionPercentage +
									'%' +
									'Date de naissance: ' +
									new Date(data.birthDate).toLocaleDateString('fr-FR', {
										year: 'numeric',
										month: '2-digit',
										day: '2-digit',
									}) +
									' Nif: ' +
									data.nif,
							)
							.toString(),
					},
				],
		  }
		: {}),

	...(BankAccountOfSubscription?.id &&
	BankAccountOfSubscription?.label &&
	BankAccountOfSubscription?.holder &&
	BankAccountOfSubscription?.BIC &&
	BankAccountOfSubscription?.IBAN
		? {
				'Compte bancaire': [
					{ key: fieldTranslation.id, value: BankAccountOfSubscription?.id },
					{ key: fieldTranslation.label, value: BankAccountOfSubscription?.label },
					{ key: fieldTranslation.holder, value: BankAccountOfSubscription?.holder },
					{ key: fieldTranslation.bic, value: BankAccountOfSubscription?.BIC },
					{ key: fieldTranslation.iban, value: BankAccountOfSubscription?.IBAN },
				],
		  }
		: {}),
	'Tech Data': [
		{ title: 'KYC' },
		{ key: 'id', value: kycAdequationHistoriqueFiscale?.kyc?.id },
		{ key: 'createdAt', value: kycAdequationHistoriqueFiscale?.kyc?.createdAt },
		{ key: 'updatedAt', value: kycAdequationHistoriqueFiscale?.kyc?.updatedAt },
		{
			key: 'lastApicilActualizationAt',
			value: kycAdequationHistoriqueFiscale?.kyc?.lastApicilActualizationAt,
		},

		{ title: 'Adequation' },

		{ key: 'scpiSignedAt', value: kycAdequationHistoriqueFiscale?.adequation?.scpiSignedAt },
		{ key: 'peSignedAt', value: kycAdequationHistoriqueFiscale?.adequation?.peSignedAt },
		{ key: 'cwdfdSignedAt', value: kycAdequationHistoriqueFiscale?.adequation?.cwdfdSignedAt },
		{ key: 'artSignedAt', value: kycAdequationHistoriqueFiscale?.adequation?.artSignedAt },

		{ title: 'Personne Morale' },

		subscription?.productType === ProductType.PE ||
		subscription?.productType === ProductType.SCPI ||
		subscription?.productType === ProductType.CASH
			? {
					key: 'id',
					value: subscription?.moralPersonId,
			  }
			: {},

		{ key: 'createdAt', value: moralPersonOfSubscription?.createdAt },
		{ key: 'updatedAt', value: moralPersonOfSubscription?.updatedAt },

		{ title: 'Client' },

		{ key: 'id', value: subscription?.user?.id },
		{ key: 'createdAt', value: subscription?.user?.createdAt },
		{ key: 'updatedAt', value: subscription?.user?.updatedAt },
		{ key: 'isMfaEnabled', value: subscription?.user?.isMfaEnabled },
		{ key: 'email', value: subscription?.user?.email },
		{ key: 'mfaMethod', value: subscription?.user?.mfaMethod },
		{ key: 'partnerId', value: subscription?.user?.partnerId },
		{ key: 'hubspotId', value: subscription?.user?.hubspotId },
		{ key: 'intercomId', value: subscription?.user?.intercomId },
		{ key: 'sponsoredByCode', value: subscription?.user?.sponsoredByCode },
		{ key: 'isDemo', value: subscription?.user?.isDemo },
		{ key: 'isPhoenix', value: subscription?.user?.isPhoenix },
		{ key: 'isCorporate', value: subscription?.user?.isCorporate },
		{ key: 'isBlack', value: subscription?.user?.isBlack },
		{ key: 'hasBlockingInstance', value: subscription?.hasBlockingInstance },
		{ title: 'Infos souscription' },

		{ key: 'compte bancaire lié', value: BankAccountOfSubscription?.id },
		subscription && 'endDate' in subscription ? { key: 'endDate', value: subscription?.endDate } : {},
		subscription && 'contractSentToClientAt' in subscription
			? { key: 'contractSentToClientAt', value: subscription?.contractSentToClientAt }
			: {},
		subscription && 'paymentAt' in subscription ? { key: 'paymentAt', value: subscription?.paymentAt } : {},
		subscription && 'signedAt' in subscription ? { key: 'signedAt', value: subscription?.signedAt } : {},
		subscription && 'partnerValidationAt' in subscription
			? { key: 'partnerValidationAt', value: subscription?.partnerValidationAt }
			: {},
		subscription && 'kycValidationAt' in subscription
			? { key: 'kycValidationAt', value: subscription?.kycValidationAt }
			: {},
		subscription && 'createdAt' in subscription
			? { key: 'subscription createdAt', value: subscription?.createdAt }
			: {},
		subscription && 'pendingAt' in subscription ? { key: 'pendingAt', value: subscription?.pendingAt } : {},
		subscription && 'requestedAt' in subscription ? { key: 'requestedAt', value: subscription?.requestedAt } : {},
		subscription && 'registeredAt' in subscription ? { key: 'registeredAt', value: subscription?.registeredAt } : {},
		subscription && 'completedAt' in subscription ? { key: 'completedAt', value: subscription?.completedAt } : {},
		subscription && 'terminatedAt' in subscription ? { key: 'terminatedAt', value: subscription?.terminatedAt } : {},
	],
});

export default generateUserInfoPayload;
