import { SearchBy } from 'components/filters/SearchBy';
import useSessionStorageState from 'hooks/useSessionStorate';
import { SubscriptionStatus } from 'types/global.type';
import { TransferStatus } from 'types/perTransfer.type';

export const statusOptions = [
	SubscriptionStatus.REQUESTED,
	SubscriptionStatus.SIGNED,
	SubscriptionStatus.PARTNER_VALIDATION,
	SubscriptionStatus.PARTNER_TREATMENT,
	SubscriptionStatus.PAYMENT,
	SubscriptionStatus.COMPLETED,
];

const initialState = {
	statusFilter: statusOptions,
	onlyOverdue: false,
	input: '',
	searchBy: 'email' as const,
};

const useTransferFilters = (context: string, customSearchBy?: SearchBy, customInput?: string) => {
	const [input, setInput] = useSessionStorageState(
		`${context}_TRANSFER_SEARCH_INPUT`,
		customInput ?? initialState.input,
	);
	const [searchBy, setSearchBy] = useSessionStorageState<SearchBy>(
		`${context}_TRANSFER_SEARCH_BY`,
		customSearchBy ?? initialState.searchBy,
	);
	const [statusFilter, setStatusFilter] = useSessionStorageState<TransferStatus[]>(
		`${context}_TRANSFER_STATUS_FILTER`,
		[
			SubscriptionStatus.REQUESTED,
			SubscriptionStatus.SIGNED,
			SubscriptionStatus.PARTNER_VALIDATION,
			SubscriptionStatus.PARTNER_TREATMENT,
			SubscriptionStatus.PAYMENT,
		],
	);
	const [onlyOverdue, setOnlyOverdue] = useSessionStorageState<boolean>(
		`${context}_TRANSFER_ONLY_OVERDUE`,
		initialState.onlyOverdue,
	);

	const resetFilters = () => {
		setInput(initialState.input);
		setSearchBy(initialState.searchBy);
		setStatusFilter([
			SubscriptionStatus.REQUESTED,
			SubscriptionStatus.SIGNED,
			SubscriptionStatus.PARTNER_VALIDATION,
			SubscriptionStatus.PARTNER_TREATMENT,
			SubscriptionStatus.PAYMENT,
		]);
		setOnlyOverdue(initialState.onlyOverdue);
	};

	return {
		input,
		setInput,
		searchBy,
		setSearchBy,
		statusFilter,
		setStatusFilter,
		onlyOverdue,
		setOnlyOverdue,
		resetFilters,
	};
};

export default useTransferFilters;
