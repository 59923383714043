import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
	Button,
	chakra,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
	Select,
	Textarea,
	VStack,
} from '@chakra-ui/react';

import { EmailAttestationDeposit, OpsPeDeal, useSendDepositAttestationMutation } from 'services/deal';
import { RamifyDoc } from 'services/document';
import { PEFund } from 'types/airtable/pe.airtable.type';
import { OPS, opsDisplayName, opsToEmail } from 'types/ops-list.type';
import { isNone } from 'utils/functions';

type DealSendDepositAttestationProps = {
	deal: OpsPeDeal;
	fund: PEFund;
	defaultText?: string;
	defaultReceiver?: string;
	defaultSubject?: string;
	depositAttestation: RamifyDoc;
};

const defaultMessage = `Bonjour,

Vous trouverez en pièce jointe l'avis de virement pour le client cité en objet.
Merci de bien confirmer la complétude du dossier.

Bien à vous,`;

const DealSendDepositAttestation: FC<DealSendDepositAttestationProps> = ({
	deal,
	fund,
	defaultText = defaultMessage,
	defaultReceiver,
	defaultSubject,
	depositAttestation,
}) => {
	const methods = useForm<EmailAttestationDeposit>({
		defaultValues: {
			message: defaultText,
			receiver: defaultReceiver ?? fund['Email - MO/BO'],
			subject: defaultSubject ?? `Ramify - Attestation de dépôt - ${deal.user.kyc.lastName} ${deal.user.kyc.firstName}`,
		},
	});
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = methods;

	const [sendAttestation, { isLoading: isEmailSending }] = useSendDepositAttestationMutation();

	const handleSendDepositAttestation = handleSubmit((data: EmailAttestationDeposit) => {
		sendAttestation({
			...data,
			attachment: {
				documentName: depositAttestation.documentName,
				filename: depositAttestation.filename,
				url: depositAttestation.url,
			},
		});
	});

	if (isNone(deal)) return null;

	return (
		<chakra.form onSubmit={handleSendDepositAttestation} w="100%">
			<VStack align="start" w="100%">
				<HStack w="100%" align="start" borderWidth="2px" borderColor="gray.100" p="8px">
					<VStack flex={1} align="start">
						<FormControl w="100%">
							<HStack w="100%" align="start">
								<FormLabel w="120px">Expediteur</FormLabel>
								<Select {...register('sender', { required: true })}>
									{Object.values(OPS)
										.filter((v) => ![OPS.AMELIE].includes(v))
										.map((ops) => (
											<option key={ops} value={opsToEmail[ops]}>
												{opsDisplayName[ops]} ({opsToEmail[ops]})
											</option>
										))}
								</Select>
							</HStack>
							{errors.sender && <FormErrorMessage>Ce champ est requis</FormErrorMessage>}
						</FormControl>
						<FormControl w="100%" isInvalid={!!errors.receiver}>
							<HStack w="100%" align="start">
								<FormLabel w="120px">Destinataire</FormLabel>
								<Input placeholder="Destinataire" {...register('receiver', { required: true })} />
							</HStack>
							{errors.receiver && <FormErrorMessage>Ce champ est requis</FormErrorMessage>}
						</FormControl>
						<FormControl w="100%" isInvalid={!!errors.subject}>
							<HStack w="100%" align="start">
								<FormLabel w="120px">Objet</FormLabel>
								<Input placeholder="Objet" {...register('subject', { required: true })} />
							</HStack>
							{errors.subject && <FormErrorMessage>Ce champ est requis</FormErrorMessage>}
						</FormControl>
					</VStack>

					<VStack flex={1} align="end" spacing="8px">
						<FormControl w="100%" isInvalid={!!errors.message}>
							<Textarea h="185px" placeholder="Message" {...register('message', { required: true })} />
							{errors.message && <FormErrorMessage>Ce champ est requis</FormErrorMessage>}
						</FormControl>
						<Button
							colorScheme="blue"
							isDisabled={depositAttestation === undefined}
							isLoading={isEmailSending}
							type="submit"
						>
							Envoyer
						</Button>
					</VStack>
				</HStack>
			</VStack>
		</chakra.form>
	);
};

export default DealSendDepositAttestation;
