import { FC, useState } from 'react';
import {
	Box,
	Center,
	Divider,
	Heading,
	Step,
	StepIcon,
	StepIndicator,
	StepNumber,
	Stepper,
	StepSeparator,
	StepStatus,
	Text,
	useSteps,
	VStack,
} from '@chakra-ui/react';

import { User } from 'types/user.type';

import NotionProposalSelectAccount from './forms/Account';
import PropalDefiscalisation from './forms/Defiscalisation';
import NotionProposalReviewInfosClient from './forms/ReviewInformations';

const FiscalityPropale: FC = () => {
	const [selectedUser, setSelectedUser] = useState<User | undefined>();

	const steps = [
		{ title: 'Compte Client', description: 'Sélection ou création du client' },
		{ title: 'Informations', description: 'Vérification des informations' },
		{ title: "Proposition d'investissement", description: 'Création de la proposition notion' },
	];

	const { activeStep, goToNext, goToPrevious } = useSteps({
		index: 0,
		count: steps.length,
	});

	return (
		<Center p="24px" w="100%" pb="80px">
			<Box p="32px" borderRadius="10px" borderWidth="1px" borderColor="grey.200" w="100%" maxW="1080px" bg="white">
				<VStack align="start" spacing="16px" w="100%">
					<Heading size="md">Proposition d'investissement - Fiscalité 2023</Heading>
					<Divider />
					<Stepper size="lg" index={activeStep} w="100%">
						{steps.map((step) => (
							<Step key={step.title}>
								<StepIndicator boxSize={8}>
									<StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
								</StepIndicator>

								<Box flexShrink="0">
									<Heading size="sm">{step.title}</Heading>
									<Text fontSize="sm">{step.description}</Text>
								</Box>

								<StepSeparator />
							</Step>
						))}
					</Stepper>

					{activeStep === 0 && (
						<NotionProposalSelectAccount
							selectedUser={selectedUser}
							setSelectedUser={setSelectedUser}
							onNext={(user) => {
								setSelectedUser(user);
								goToNext();
							}}
						/>
					)}

					{selectedUser && activeStep === 1 && (
						<NotionProposalReviewInfosClient user={selectedUser} onNext={goToNext} onPrev={goToPrevious} />
					)}

					{selectedUser && activeStep === 2 && <PropalDefiscalisation user={selectedUser} onPrev={goToPrevious} />}
				</VStack>
			</Box>
		</Center>
	);
};

export default FiscalityPropale;
