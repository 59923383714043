import { useMemo } from 'react';

import { AllDeal } from 'services/deal';
import { useGetStatusOptionsQuery } from 'services/ops/status';
import { ProductType, SubscriptionStatus } from 'types/global.type';
import { isNone, isNotNone } from 'utils/functions';

export const getProductTypeKey = (deal: AllDeal, statuses: string[]) => {
	// does fund have specific statuses ? (ex: Eurazeo Entrepreneurs Club 2)
	if ('fund' in deal && isNotNone(deal.fund) && statuses.includes(deal.fund)) return deal.fund;

	let keySuffix = '';
	const isInAppSignFund = deal.productType !== ProductType.ART && 'process' in deal && deal.process === 'mail';

	if (isInAppSignFund) {
		// PE / SCPI / CROWDFUNDING funds have normal or AUTO statuses depending on if the fund is automated with in app sign
		keySuffix = '_AUTO';
	} else if (deal.productType === ProductType.CASH) {
		// CAT/CER have specific statuses for each provider and person type (moral / physical)
		return `${deal.type}_${deal.legalEntity}_${deal.provider}`;
	} else if (deal.productType === ProductType.INVEST && deal.dealType === 'TRANSFER') {
		return 'TRANSFER';
	}

	return `${deal.productType}${keySuffix}`;
};

const useDealStatus = (deal: AllDeal) => {
	const { data: statusOptions } = useGetStatusOptionsQuery();

	const dealStatusOptions = useMemo<SubscriptionStatus[]>(() => {
		if (isNone(statusOptions)) return [];

		const productTypeKey = getProductTypeKey(deal, Object.keys(statusOptions));
		const options = statusOptions[productTypeKey];

		// if deal has a status and it's not in the options, add it to the list
		if (deal.status && !options.includes(deal.status)) return [deal.status, ...options];
		return options;
	}, [deal, statusOptions]);

	const nextStatus = useMemo<SubscriptionStatus | null>(() => {
		const index = dealStatusOptions.indexOf(deal.status);
		if (index === dealStatusOptions.length - 1) return null;
		return dealStatusOptions.at(dealStatusOptions.indexOf(deal.status) + 1) ?? null;
	}, [deal, dealStatusOptions]);

	return { dealStatusOptions, nextStatus };
};

export default useDealStatus;
